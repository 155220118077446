import { useTranslation } from 'react-i18next';
import React from 'react';
import Modal from '~/components/Common/Modal/Modal';

const ModalExpectedChanges = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { t } = useTranslation('Application');
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(
        'ExpectedChangesModal.are_there_any_expected_changes_in_your_personal_circumstances_that_would_result_in_you_being_unable_to_repay_the_loan_over_the_term_of_the_agreement'
      )}
    >
      <ExpectedChangesModalContent />
    </Modal>
  );
};

const ExpectedChangesModalContent = () => {
  const { t } = useTranslation('Application');
  return (
    <div>
      <p>
        {t(
          'ExpectedChangesModal.are_you_aware_of_any_potential_changes_to_your_personal_circumstances_that_may_impact_upon_your_ability_to_make_loan_repayments_over_the_term_of_the_agreement_e_g_retirement_redundancy_reduction_in_working_hours_salary_career_change_or_change_in_family_circumstances'
        )}
      </p>
    </div>
  );
};

export default ModalExpectedChanges;
