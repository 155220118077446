import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as applicationActions from '../../../redux/application/applicationActions';
import ApplicationPage from './ApplicationPage';
import FormFooter from '../../Common/Form/FormFooter';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import Page from '../../Common/Page';
import ApplicationSummaryTables from './ApplicationSummaryTables';
import BenefitsAndRisks from './BenefitsAndRisks';
import BenefitsAndRisksFooter from './BenefitsAndRisksFooter';
import ConsumerVehicleInfoPanel from '../../Common/ConsumerVehicleInfoPanel';
import RadioButton from '../../Common/Form/RadioButton';
import CheckboxWithLabel from '../../Common/Form/CheckboxWithLabel';
import { getCustomer } from '../../../selectors/customerSelectors';
import { observer, inject } from 'mobx-react';
import MarketingPreferences from '../../Common/MarketingPreferences';
import { countItemsInObject } from '../../../core/helpers';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import ToggleSwitch from '../../Common/ToggleSwitch';
import _ from 'lodash';
import { compose } from 'redux';
import { ArrangedFlags } from '../../../constants';
import { isOnlineSource } from '../../../utils/application';
import { withNavigate, withOutletContextProps, withParams } from '../../../hocs/router';
import { canSeeConsumerDutyContent } from '../../../features';
import { printPageByRef } from '~/core/print';
import { ApplicationSummaryVariantJs } from './applicationSummaryTypes';
import './editApplicationSummary.scss';

/*
 * - Served when user clicks the 'Edit the application and re-submit' CTA on the Application Status page
 * -- However, clicking the 'Edit the application and re-submit' CTA on the Application Status page will
 * -- result in the ApplicationSummary component being served instead of this one.
 * -- The difference is, ApplicationSummary displays the application sidebar tabs whereas this one does not.
 */
class EditApplicationSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBenefitsPageRadio: null,
      benefitsPageCheckbox: false,
      validStatements: false,
      distanceSaleCheckbox: _.get(
        this.props.application,
        'Arranged.DistanceSale',
        this.canUseDistanceSale ? false : null
      ),
      marketingPreference:
        (this.props.application.PrivacyPreferences &&
          this.props.application.PrivacyPreferences.LenderMarketingByEmailConsent) ||
        false
    };
  }

  printAreaRef = React.createRef();

  get canUseDistanceSale() {
    return this.props.appStore.uiState.canUseDistanceSale(
      this.props.application.CustomerType,
      this.props.application.Quote.FunderCode
    );
  }
  componentDidMount() {
    this.props.fetchApplicationUpdates(this.props.params.applicantId);
  }

  handleCustomerLocationRadioUpdate = (e) => {
    this.setState({
      selectedBenefitsPageRadio: e.target.value
    });
  };
  handleCheckboxUpdateBenefitsPage = (checked) => {
    this.setState({
      benefitsPageCheckbox: checked
    });
  };
  handleChangeCheckboxValidStatements = (checked) => {
    this.setState({
      validStatements: checked
    });
  };
  handleProceed = () => {
    if (this.state.selectedBenefitsPageRadio) {
      //A radio option must be selected
      if (this.state.benefitsPageCheckbox) {
        //And the checkbox ticked
        this.handleSubmit();
      } else {
        if (this.refs.checkboxWarning) {
          this.refs.checkboxWarning.style.display = 'block';
        }
      }
    } else {
      if (this.refs.radioWarning) {
        this.refs.radioWarning.style.display = 'block';
      }
    }
  };
  handleBack = () => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`);
  };

  handlePrint = () => {
    printPageByRef(this.printAreaRef);
  };

  handleSubmit = () => {
    let marketing = this.props.appStore.legalDocumentStore.getLenderMarketing(this.props.application.Quote.FunderCode);
    let marketingVersion = 0;

    if (marketing) {
      marketingVersion = marketing.metadata.Version;
    }

    this.props.dispatch(
      applicationActions.resubmitApplication(
        this.props.params.dealershipId,
        this.props.params.consumerId,
        this.props.application.Id,
        this.props.application.CustomerType,
        this.state.marketingPreference,
        marketingVersion,
        this.state.distanceSaleCheckbox,
        this.state.selectedBenefitsPageRadio
      )
    );
  };
  toggleMarketing = () => {
    this.setState({
      marketingPreference: !this.state.marketingPreference
    });
  };
  handleCheckboxDistanceSale = (toggle) => {
    this.setState({
      distanceSaleCheckbox: toggle,
      // If the distance sale checkbox is toggled, only reset "The customer is present" radio if it's currently selected
      selectedBenefitsPageRadio:
        this.state.selectedBenefitsPageRadio === ArrangedFlags.Showroom ? null : this.state.selectedBenefitsPageRadio
    });
  };
  openModal = (name) => {
    return () => {
      this.setState({
        modalOpen: name
      });
    };
  };
  closeModal = () => {
    this.setState({
      modalOpen: ''
    });
  };

  render() {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    const canSubmitQuote = this.props.appStore.uiState.canSubmitApplication(this.props.application.Quote.FunderCode);
    const readyToSubmit =
      countItemsInObject(this.props.application.validationErrors) === 0 &&
      this.state.benefitsPageCheckbox &&
      this.state.validStatements &&
      this.state.selectedBenefitsPageRadio;

    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('EditApplicationSummary.home'),
              path: `/d/${dealershipId}`
            },
            {
              name: this.props.t('EditApplicationSummary.customer_list'),
              path: `/d/${dealershipId}/consumers`
            },
            {
              name: this.props.t('EditApplicationSummary.consumer'),
              path: `/d/${dealershipId}/consumers/${consumerId}`
            },
            {
              name: this.props.t('EditApplicationSummary.application_status'),
              path: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`
            },
            {
              name: this.props.t('EditApplicationSummary.edit_application'),
              path: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`
            },
            {
              name: this.props.t('EditApplicationSummary.resubmission_summary')
            }
          ]}
          consumer={this.props.customer}
        />
        <ConsumerVehicleInfoPanel vehicle={this.props.vehicle} quote={this.props.application.Quote} />
        <Panel>
          <PanelHeader>{this.props.t('EditApplicationSummary.resubmission_summary')}</PanelHeader>
          <ApplicationPage>
            <div className="printArea" ref={this.printAreaRef}>
              <ApplicationSummaryTables
                options={this.props.options}
                application={this.props.application}
                applicationFields={this.props.applicationFields}
                showValidationTicks={false}
                showConsumerHubInfoPanel={isOnlineSource(this.props.application)}
                variant={ApplicationSummaryVariantJs.EditApplication}
              />
              <div className="editApplicationSummary">
                {!canSeeConsumerDutyContent() && (
                  <BenefitsAndRisks quote={this.props.application.Quote} email={this.props.application.Email}>
                    <MarketingPreferences
                      funderCode={this.props.application.Quote.FunderCode}
                      toggleMarketing={this.toggleMarketing}
                      hasAcceptedMarketing={this.state.marketingPreference}
                    />
                  </BenefitsAndRisks>
                )}
              </div>
            </div>
            <BenefitsAndRisksFooter />
            <div>
              <CheckboxWithLabel
                onChange={this.handleChangeCheckboxValidStatements}
                id="valid-statements"
                value={this.state.validStatements}
                dataThook="validStatements"
              >
                {this.props.t('EditApplicationSummary.i_can_confirm_that_the_above_statements_are_true')}
              </CheckboxWithLabel>
              <CheckboxWithLabel
                onChange={this.handleCheckboxUpdateBenefitsPage}
                id="readBenefits"
                value={this.state.benefitsPageCheckbox}
                dataThook="readBenefits"
              >
                {this.props.t(
                  'EditApplicationSummary.i_can_confirm_that_the_customer_has_read_and_understood_the_benefits_and_risks_of_the_finance_product_selected_and_is_aware_of_the_existence_and_nature_of_any_commission_to_be_paid_by_the_lender'
                )}
              </CheckboxWithLabel>
              <div className="editApplicationSummary__checkboxWarning" ref="checkboxWarning">
                {this.props.t('EditApplicationSummary.you_must_confirm_this_to_proceed')}
              </div>
            </div>

            {canSubmitQuote && (
              <>
                {this.canUseDistanceSale && (
                  <div className="applicationSummary__distanceSale">
                    <MobxFormLabel htmlFor="distanceSale">Is this a distance sale?</MobxFormLabel>
                    <div className="applicationSummary__Toggle">
                      <ToggleSwitch
                        id="distanceSale"
                        isActive={this.state.distanceSaleCheckbox}
                        handleUpdate={this.handleCheckboxDistanceSale}
                      />
                    </div>
                  </div>
                )}
                <div className="editApplicationSummary__radio">
                  {!this.state.distanceSaleCheckbox && (
                    <RadioButton
                      id="radio-present"
                      name="customer-location"
                      onChange={this.handleCustomerLocationRadioUpdate}
                      ref="radio1"
                      value={ArrangedFlags.Showroom}
                      checked={this.state.selectedBenefitsPageRadio === ArrangedFlags.Showroom}
                    >
                      {this.props.t('EditApplicationSummary.the_customer_is_present')}
                    </RadioButton>
                  )}
                  <RadioButton
                    id="radio-online"
                    name="customer-location"
                    onChange={this.handleCustomerLocationRadioUpdate}
                    ref="radio2"
                    value={ArrangedFlags.Online}
                    checked={this.state.selectedBenefitsPageRadio === ArrangedFlags.Online}
                  >
                    {this.props.t('EditApplicationSummary.arranged_online')}
                  </RadioButton>
                  <RadioButton
                    id="radio-phone"
                    name="customer-location"
                    onChange={this.handleCustomerLocationRadioUpdate}
                    ref="radio3"
                    value={ArrangedFlags.Phone}
                    checked={this.state.selectedBenefitsPageRadio === ArrangedFlags.Phone}
                  >
                    {this.props.t('EditApplicationSummary.arranged_over_the_phone')}
                  </RadioButton>
                </div>
                <div className="editApplicationSummary__checkboxWarning" ref="radioWarning">
                  {this.props.t('EditApplicationSummary.you_must_specify_this_to_proceed')}
                </div>
              </>
            )}

            <FormFooter
              submitLabel={this.props.t('EditApplicationSummary.submit')}
              onSubmit={this.handleProceed}
              onCancel={this.handleBack}
              submitDisabled={!readyToSubmit}
              hideSubmit={!this.props.appStore.uiState.canGenerateQuotes || !canSubmitQuote}
              submittingState={this.props.application.submittingState}
              onPrint={this.handlePrint}
              cancelLabel={this.props.t('EditApplicationSummary.go_back')}
            />
          </ApplicationPage>
        </Panel>
      </Page>
    );
  }
}

EditApplicationSummary.propTypes = {
  params: PropTypes.object,
  customer: PropTypes.object,
  application: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  appStore: PropTypes.object,
  session: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  applicationFields: PropTypes.object.isRequired,
  claims: PropTypes.array,
  vehicle: PropTypes.object,
  assignees: PropTypes.array,
  dealership: PropTypes.object,
  fetchApplicationUpdates: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    customer: getCustomer(state, ownProps.params.consumerId),
    application: state.application
  };
}

export default compose(
  withParams,
  withNavigate,
  withOutletContextProps,
  withTranslation('Application'),
  inject(['appStore']),
  connect(mapStateToProps),
  observer
)(EditApplicationSummary);
