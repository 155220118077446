import { useTranslation } from 'react-i18next';
import React from 'react';
import Modal from '~/components/Common/Modal/Modal';

const ReplacementModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { t } = useTranslation('Application');
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('ReplacementModal.is_this_a_replacement_loan')}>
      <ReplacementModalContent />
    </Modal>
  );
};

const ReplacementModalContent = () => {
  const { t } = useTranslation('Application');
  return (
    <div>
      <p>
        {t(
          'ReplacementModal.please_confirm_if_this_new_monthly_commitment_is_a_replacement_for_an_existing_loan_repayment_which_will_be_settled_as_part_of_this_process'
        )}
      </p>
    </div>
  );
};

export default ReplacementModal;
