import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '~/components/Common/Modal/Modal';
import './modalGross.scss';

const ModalGross = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { t } = useTranslation('Application');
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('ModalGross.gross_annual_income')}>
      <ModalGrossContent />
    </Modal>
  );
};

const ModalGrossContent = () => {
  const { t } = useTranslation('Application');
  return (
    <div>
      <div>
        <h1 className="modalGross__header">{t('ModalGross.employment')}</h1>
        <p>
          {t('ModalGross.the_following_defines_the_allowable_values_which_can_be_included_in_the_gross_annual_income')}
        </p>
        <h1 className="modalGross__listHeader">{t('ModalGross.allowable')}</h1>
        <ul>
          <li>{t('ModalGross.wage_or_salary_from_full_or_part_time_employment')}</li>
          <li>
            {t(
              'ModalGross.overtime_or_commission_if_it_is_received_regularly_and_it_is_unlikely_to_be_withdrawn_e_g_weekly_payments_for_12_weeks_monthly_payments_for_three_months_quarterly_payments_for_two_quarters'
            )}
          </li>
          <li>
            {t(
              'ModalGross.permanent_allowances_included_in_the_customers_regular_salary_e_g_car_allowance_mortgage_subsidy_or_london_allowance'
            )}
          </li>
          <li>
            {t(
              'ModalGross.income_from_self_employment_this_must_be_the_customers_drawings_from_the_business_which_must_be_received_into_a_personal_account'
            )}
          </li>
        </ul>
        <h1 className="modalGross__listHeader">{t('ModalGross.not_allowable')}</h1>
        <ul>
          <li>{t('ModalGross.non_regular_overtime_or_commission')}</li>
          <li>{t('ModalGross.bonuses')}</li>
        </ul>
      </div>
      <div>
        <h1 className="modalGross__header">{t('ModalGross.retirement_independent_means')}</h1>
        <h1 className="modalGross__listHeader">{t('ModalGross.allowable')}</h1>
        <ul>
          <li>{t('ModalGross.pension_received_from_private_company_or_government_pensions')}</li>
          <li>{t('ModalGross.pension_credit')}</li>
          <li>{t('ModalGross.working_tax_credit')}</li>
          <li>{t('ModalGross.child_tax_credit')}</li>
          <li>{t('ModalGross.child_benefit')}</li>
          <li>{t('ModalGross.employment_and_support_allowance_esa')}</li>
          <li>{t('ModalGross.carers_allowance')}</li>
          <li>{t('ModalGross.guardians_allowance')}</li>
          <li>{t('ModalGross.blind_person_allowance')}</li>
          <li>{t('ModalGross.industrial_injuries_disablement')}</li>
          <li>{t('ModalGross.widowed_parents_allowance')}</li>
        </ul>
        <h1 className="modalGross__listHeader">{t('ModalGross.not_allowable')}</h1>
        <ul>
          <li>{t('ModalGross.income_from_a_spouse_civil_partner_or_a_partner_e_g_housekeeping_or_allowance')}</li>
          <li>{t('ModalGross.job_seekers_allowance')}</li>
          <li>{t('ModalGross.income_support')}</li>
          <li>{t('ModalGross.disabled_students_allowance')}</li>
          <li>
            {t(
              'ModalGross.personal_independence_payment_formerly_known_as_disability_living_allowance_that_is_not_designed_to_meet_additional_costs_incurred_by_disability_such_as_transportation_and_care'
            )}
          </li>
          <li>{t('ModalGross.winter_fuel_payments')}</li>
          <li>{t('ModalGross.attendance_allowance')}</li>
          <li>{t('ModalGross.housing_benefit')}</li>
          <li>{t('ModalGross.council_tax_benefit')}</li>
          <li>
            {t(
              'ModalGross.social_fund_e_g_funeral_payments_maternity_grants_crisis_loans_budgeting_loans_community_care_grants_and_cold_weather_payments'
            )}
          </li>
        </ul>
      </div>
      <div>
        <h1 className="modalGross__header">{t('ModalGross.other')}</h1>
        <h1 className="modalGross__listHeader">{t('ModalGross.allowable')}</h1>
        <ul>
          <li>
            {t(
              'ModalGross.regularly_received_contractual_payments_which_can_be_evidenced_through_personal_account_deposits'
            )}
          </li>
        </ul>
        <h1 className="modalGross__listHeader">{t('ModalGross.not_allowable')}</h1>
        <ul>
          <li>{t('ModalGross.student_grants_loans_and_bursaries')}</li>
          <li>{t('ModalGross.any_other_income_not_listed_as_allowable')}</li>
        </ul>
      </div>
    </div>
  );
};

export default ModalGross;
