import { VehicleLoanDataType } from '~/components/QuoteCard/types';
import { formatMoney, formatExcess } from '../../core/helpers';
import { FacilityType } from '../contentService/utils';

export type QuestionProps = {
  heading: string;
  questionText: string;
  validationMsg: string;
};

export type CheckboxQuestion = {
  heading: string;
  questionText: string;
};

export type SuitabilityContent = {
  questions: QuestionProps[];
  checkbox?: CheckboxQuestion[];
};

export type RepaymentContent = {
  questions: QuestionProps[];
};

export const getTotalMileage = (Term: number, AnnualMileage: number | null): number => {
  // AnnualMileage === AnnualDistanceQuoted
  const totalMileage = ((AnnualMileage || 0) / 12) * Term;
  return Math.round(totalMileage);
};

export const getRepaymentContent = (quote: VehicleLoanDataType): RepaymentContent | undefined => {
  const { Term, FollowingPayments, CreditTier, SupportsPreApproval } = quote;
  const isRiskBasedPricing = CreditTier !== 'None';

  const monthlyPayments = formatMoney(FollowingPayments);

  if (SupportsPreApproval) {
    return {
      questions: [
        {
          heading: 'Repayments',
          questionText: `Do you understand that the payment quoted of ${monthlyPayments} is based on your Pre-Approved Quote? Your actual monthly payment and interest rate will be confirmed once we have processed your application which is subject to a full credit check. You will be given the option to review the actual monthly payment to ensure you can comfortably afford it, prior to signing an agreement.`,
          validationMsg: `You should only proceed with this product if you can afford to pay the monthly payments for the whole term of the agreement. If you cannot afford the monthly payments, you could consider changing the amount you borrow, increasing your deposit or changing the term of your agreement.`
        }
      ]
    };
  }

  if (isRiskBasedPricing === true) {
    return {
      questions: [
        {
          heading: 'Repayments',
          questionText: `Do you understand that the payment quoted of ${monthlyPayments} is indicative and based on the credit rating you selected? Your actual monthly payment and interest rate will be calculated once we have processed your application and assuming you are approved for credit. This may be higher or lower based on your personal circumstances. You will be given the option to review the actual monthly payment to ensure you can comfortably afford it, prior to signing an agreement.`,
          validationMsg: `You should only proceed with this product if you can afford to pay the monthly payments for the whole term of the agreement. If you cannot afford the monthly payments, you could consider changing the amount you borrow, increasing your deposit or changing the term of your agreement.`
        }
      ]
    };
  }

  if (isRiskBasedPricing === false) {
    return {
      questions: [
        {
          heading: 'Repayments',
          questionText: `Do you believe that you will be able to comfortably afford the repayments of ${monthlyPayments} over the next ${Term} months?`,
          validationMsg: `You should only proceed with this product if you can afford to pay the monthly payments for the whole term of the agreement. If you cannot afford the monthly payments, you could consider changing the amount you borrow, increasing your deposit or changing the term of your agreement.`
        }
      ]
    };
  }

  return undefined;
};

type GetCommissionDisclosureQuestionProps = {
  quote: VehicleLoanDataType;
  dealership: any;
  funder: any;
  fiduciaryText?: string;
  asQuestion: boolean;
};
export const getCommissionDisclosureQuestion = ({
  quote,
  dealership,
  funder,
  fiduciaryText = '',
  asQuestion
}: GetCommissionDisclosureQuestionProps): QuestionProps => {
  const { Commissions } = quote;

  const defaultQuestion = {
    heading: 'Commission Disclosure',
    questionText: questionText.commissionDisclosure.default({
      dealershipName: dealership.Name,
      funderName: funder.Name,
      fiduciaryText,
      asQuestion
    }),
    validationMsg: `It is normal practice for vehicle retailers to receive a commission from lenders. This commission will not affect the payments you make to the lender.`
  };

  if (!Commissions || Commissions.length === 0) {
    return defaultQuestion;
  }

  const { Type, Amount } = Commissions[0];

  switch (Type) {
    case 'None':
      return defaultQuestion;
    case 'DIC':
    case 'FunderSpecific':
      return {
        ...defaultQuestion,
        questionText: questionText.commissionDisclosure.funderSpecificOrDIC({
          dealershipName: dealership.Name,
          funderName: funder.Name,
          amount: Amount,
          fiduciaryText,
          asQuestion
        })
      };
    case 'Fixed':
      return {
        ...defaultQuestion,
        questionText: questionText.commissionDisclosure.fixed({
          dealershipName: dealership.Name,
          funderName: funder.Name,
          amount: Amount,
          fiduciaryText,
          asQuestion
        })
      };
    case 'PercentageOfAdvance':
      return {
        ...defaultQuestion,
        questionText: questionText.commissionDisclosure.percentageOfAdvance({
          dealershipName: dealership.Name,
          funderName: funder.Name,
          amount: Amount,
          fiduciaryText,
          asQuestion
        })
      };
    case 'PercentageOfCashPrice':
      return {
        ...defaultQuestion,
        questionText: questionText.commissionDisclosure.percentageOfCashPrice({
          dealershipName: dealership.Name,
          funderName: funder.Name,
          amount: Amount,
          fiduciaryText,
          asQuestion
        })
      };
    default:
      return defaultQuestion;
  }
};

type QuestionTextProps = {
  dealershipName: string;
  funderName: string;
  amount?: number;
  fiduciaryText: string;
  asQuestion: boolean;
};
const doYouConsentText = 'Do you consent to this commission exchange between the lender and the retailer?';
export const questionText = {
  commissionDisclosure: {
    default: ({ dealershipName, funderName, fiduciaryText = '', asQuestion }: QuestionTextProps) =>
      `${dealershipName} will receive a commission from ${funderName}, and this will not affect the payments you make to the lender. ${fiduciaryText}${
        asQuestion ? doYouConsentText : ''
      }`,
    fixed: ({ dealershipName, funderName, amount, fiduciaryText = '', asQuestion }: QuestionTextProps) =>
      `${dealershipName} will receive a commission of ${formatMoney(
        amount
      )} from ${funderName}. This will not affect the amount you pay under this agreement. This Commission is a fixed fee the lender has agreed with the dealer based on the amount you borrow and the term of the agreement. ${fiduciaryText}${
        asQuestion ? doYouConsentText : ''
      }`,
    percentageOfAdvance: ({ dealershipName, funderName, amount, fiduciaryText = '', asQuestion }: QuestionTextProps) =>
      `${dealershipName} will receive a commission of ${formatMoney(
        amount
      )} from ${funderName}. This will not affect the amount you pay under this agreement. This Commission is a variable amount the lender has agreed with the dealer based on the amount you borrow and the term of the agreement. ${fiduciaryText}${
        asQuestion ? doYouConsentText : ''
      }`,
    percentageOfCashPrice: ({
      dealershipName,
      funderName,
      amount,
      fiduciaryText = '',
      asQuestion
    }: QuestionTextProps) =>
      `${dealershipName} will receive a commission of ${formatMoney(
        amount
      )} from ${funderName}. This will not affect the amount you pay under this agreement. This Commission is a variable amount the lender has agreed with the dealer based on the retail price of the vehicle and the term of the agreement. ${fiduciaryText}${
        asQuestion ? doYouConsentText : ''
      }`,
    funderSpecificOrDIC: ({ dealershipName, funderName, amount, fiduciaryText = '', asQuestion }: QuestionTextProps) =>
      `${dealershipName} will receive a commission of ${formatMoney(
        amount
      )} from ${funderName}. This will not affect the amount you pay under this agreement. ${fiduciaryText}${
        asQuestion ? doYouConsentText : ''
      }`
  }
};

export const getSuitabilityContent = (
  quote: VehicleLoanDataType,
  financeType: FacilityType
): SuitabilityContent | undefined => {
  const { Term, FinalPayment, AnnualDistanceQuoted, ChargePerOverDistanceUnit } = quote;
  const totalMileage = getTotalMileage(Term, AnnualDistanceQuoted);
  const excessMileageCharge = formatExcess(ChargePerOverDistanceUnit);

  switch (financeType) {
    case 'PCP':
      return {
        questions: [
          {
            heading: 'Agreement',
            questionText: `The contracted agreement term is ${Term} months, however, are you aware you can settle this agreement at anytime?`,
            validationMsg: `With this agreement you can settle at any time and if you do settle early, you may pay less interest (subject to interest being applicable).`
          }
        ],
        checkbox: [
          {
            heading: 'Annual Mileage',
            questionText: `It’s important to use a good estimate of your annual mileage in your finance application.
            You’ve told us that your estimated annual mileage is ${AnnualDistanceQuoted}, which over your selected term of ${Term} months is ${totalMileage} miles.
            If at the end of your agreement you’ve exceeded this amount, the lender will charge you ${excessMileageCharge} for every mile over the agreed limit.`
          },

          {
            heading: 'Fair wear and tear',
            questionText: `If you decide to return the vehicle to the finance company at the end of the agreement, or if you exercise your voluntary termination rights after paying back over half of what is owed, the vehicle will need to be returned in good condition and showing only acceptable signs of fair wear and tear. If you want to know more about what counts as fair wear and tear, it’s best to contact the retailer.`
          },
          {
            heading: 'At the end of your agreement',
            questionText: `You have 3 options at the end of the agreement: 1) Pay the optional final payment of ${formatMoney(
              FinalPayment
            )} and keep the vehicle. 2) Return the vehicle without paying the final payment. Given that the vehicle is in good condition and you haven't exceeded the annual mileage allowance, you will have nothing further to pay. 3) Part exchange the vehicle subject to settlement of your existing finance agreement.`
          }
        ]
      };
    case 'HP':
    case 'CS':
      return {
        questions: [
          {
            heading: 'Agreement',
            questionText: `The contracted agreement term is ${Term} months, however, are you aware you can settle this agreement at anytime?`,
            validationMsg: `With this agreement you can settle at any time and if you do settle early, you may pay less interest (subject to interest being applicable).`
          }
        ],
        checkbox: [
          {
            heading: 'At the end of your agreement',
            questionText: `Once the final payment has been received, the finance agreement will end.`
          }
        ]
      };
    case 'LP': // includes Hire Purchase Balloon
      return {
        questions: [
          {
            heading: 'Agreement',
            questionText: `The contracted agreement term is ${Term} months, however, are you aware you can settle this agreement at anytime?`,
            validationMsg: `With this agreement you can settle at any time and if you do settle early, you may pay less interest (subject to interest being applicable).`
          }
        ],

        checkbox: [
          {
            heading: 'At the end of your agreement',
            questionText: `You have 2 options at the end of the agreement: Pay the final payment of ${formatMoney(
              FinalPayment
            )} and the vehicle is yours, or Part exchange the vehicle and use any equity as a deposit on your next vehicle.`
          }
        ]
      };
    default:
      return undefined;
  }
};
