import PropTypes from 'prop-types';
import './buttonInfoIcon.scss';
const ButtonInfoIcon = ({ onClick, dataThook }) => (
  <button
    className="buttonInfoIcon"
    type="button"
    key="icon"
    onClick={onClick}
    data-th={`${dataThook || ''}InfoIconButton`}
    aria-label={dataThook || 'InfoIconButton'}
  />
);
ButtonInfoIcon.propTypes = { onClick: PropTypes.func.isRequired };
export default ButtonInfoIcon;
