import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import FormFooter from '../../Common/Form/FormFooter';
import SelectInput from '../../Common/Form/SelectInput';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import PostcodeLookup from '../../Common/PostcodeLookup/PostcodeLookup';
import YearsMonthsField from '../../Common/Form/YearsMonthsField';
import ApplicationCard from '../../Application/components/ApplicationCard';
import ModalGross from '../modals/ModalGross';
import Modal from '../../Common/Modal/Modal';
import MiniPanel from '../../Common/MiniPanel';
import MiniPanelHeader from '../../Common/MiniPanelHeader';
import ExclamationWarningWithButton from '../../Common/ExclamationWarningWithButton';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import Button from '../../Common/Button/Button';
import { PhoneNumberInput } from '~/components/Common/Form/PhoneNumberInput';
import './employmentHistoryForm.scss';

class EmploymentHistoryForm extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.appStore.customerHistoryStore.setApplication(this.props.application);
    this.props.appStore.customerHistoryStore.setupValidation(this.props.validationRules.Items[0]);
    this.props.appStore.customerHistoryStore.setInitialValues('EmploymentHistory', this.props.defaultValues);
    this.props.progress && this.props.progress.employment < 100
      ? this.props.appStore.customerHistoryStore.handleEditAddress(0)
      : this.props.appStore.customerHistoryStore.setEditingFalse();
  }

  componentDidUpdate(prevProps) {
    if (this.props.validationRules !== prevProps.validationRules) {
      this.props.appStore.customerHistoryStore.setupValidation(this.props.validationRules.Items);
    }
  }

  onSave = () => {
    this.props.appStore.customerHistoryStore.checkWarnings();
    this.props.onSave(toJS(this.props.appStore.customerHistoryStore.formData));
  };

  submitForm = () => {
    this.props.appStore.customerHistoryStore.checkWarnings();

    if (
      !this.props.appStore.customerHistoryStore.errorCount &&
      !this.props.appStore.customerHistoryStore.showTimeAtAddressWarning
    ) {
      this.props.onSubmit(toJS(this.props.appStore.customerHistoryStore.formData));
    }

    this.props.appStore.customerHistoryStore.setErrorsToState();
  };

  render() {
    const errors = this.props.appStore.customerHistoryStore.validator.getErrors();
    const funderCode = this.props.quote.FunderCode;
    const applicationFields = this.props.applicationFields;
    const formData = this.props.appStore.customerHistoryStore.formData;
    const customerHistoryStore = this.props.appStore.customerHistoryStore;

    return (
      <MobxForm onSubmit={this.submitForm} dataTh="EmploymentHistoryForm">
        {formData.Items.map((value, index) => {
          let totalMonths = 0;

          if (!value.TimeAtEmployment.TotalMonths) {
            totalMonths = value.TimeAtEmployment.Years * 12 + value.TimeAtEmployment.Months;
          } else {
            totalMonths = value.TimeAtEmployment.TotalMonths;
          }

          return (
            <MiniPanel key={index}>
              <MiniPanelHeader title={`${this.props.t('EmploymentHistoryForm.title')} ${index + 1}`} />
              {value.isEditing === true ? (
                <div>
                  <div className="employmentHistoryForm" key={index}>
                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={customerHistoryStore.isFormSubmitted}
                      error={errors.Employer}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('EmploymentHistoryForm.employer')}</div>
                      </MobxFormLabel>

                      <TextInputWrappingInput
                        id="Employer"
                        value={value.Employer}
                        onChange={(val) => customerHistoryStore.handleFieldChange('Employer', val, index)}
                      />
                    </MobxFieldGroup>

                    {applicationFields?.EmploymentSector && (
                      <MobxFieldGroup
                        isInline
                        isFormSubmitted={customerHistoryStore.isFormSubmitted}
                        error={errors.EmploymentSector}
                      >
                        <MobxFormLabel>
                          <div>{this.props.t('EmploymentHistoryForm.employment_sector')}</div>
                        </MobxFormLabel>

                        <SelectInput
                          id="EmploymentSector"
                          options="CompanySectorExtended"
                          value={value.EmploymentSector}
                          onChange={(id, val) => customerHistoryStore.handleFieldChange(id, val, index)}
                          funderCode={funderCode}
                        />
                      </MobxFieldGroup>
                    )}

                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={customerHistoryStore.isFormSubmitted}
                      error={errors.Occupation}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('EmploymentHistoryForm.occupation')}</div>
                      </MobxFormLabel>

                      <TextInputWrappingInput
                        id="Occupation"
                        value={value.Occupation}
                        onChange={(val) => customerHistoryStore.handleFieldChange('Occupation', val, index)}
                      />
                    </MobxFieldGroup>

                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={customerHistoryStore.isFormSubmitted}
                      error={errors.OccupationBasis}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('EmploymentHistoryForm.occupation_basis')}</div>
                      </MobxFormLabel>

                      <SelectInput
                        id="OccupationBasis"
                        options="EmploymentBasis"
                        value={value.OccupationBasis}
                        onChange={(id, val) => customerHistoryStore.handleFieldChange(id, val, index)}
                      />
                    </MobxFieldGroup>

                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={customerHistoryStore.isFormSubmitted}
                      error={errors.OccupationType}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('EmploymentHistoryForm.occupation_type')}</div>
                      </MobxFormLabel>

                      <SelectInput
                        id="OccupationType"
                        options="OccupationType"
                        value={value.OccupationType}
                        funderCode={funderCode}
                        onChange={(id, val) => customerHistoryStore.handleFieldChange(id, val, index)}
                      />
                    </MobxFieldGroup>

                    <PostcodeLookup
                      onSubmit={(val) => customerHistoryStore.handlePostCodeLookup(val, index)}
                      defaultValue={value.Postcode}
                      inline
                    />

                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={customerHistoryStore.isFormSubmitted}
                      error={errors.BuildingName}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('EmploymentHistoryForm.building_name')}</div>
                      </MobxFormLabel>

                      <TextInputWrappingInput
                        id="BuildingName"
                        value={value.BuildingName}
                        onChange={(val) => customerHistoryStore.handleFieldChange('BuildingName', val, index)}
                      />
                    </MobxFieldGroup>

                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={customerHistoryStore.isFormSubmitted}
                      error={errors.BuildingName}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('EmploymentHistoryForm.building_number')}</div>
                      </MobxFormLabel>

                      <TextInputWrappingInput
                        id="BuildingNumber"
                        value={value.BuildingNumber}
                        onChange={(val) => customerHistoryStore.handleFieldChange('BuildingNumber', val, index)}
                      />
                    </MobxFieldGroup>

                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={customerHistoryStore.isFormSubmitted}
                      error={errors.SubBuilding}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('EmploymentHistoryForm.room_floor')}</div>
                      </MobxFormLabel>

                      <TextInputWrappingInput
                        id="SubBuilding"
                        value={value.SubBuilding}
                        onChange={(val) => customerHistoryStore.handleFieldChange('SubBuilding', val, index)}
                      />
                    </MobxFieldGroup>

                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={customerHistoryStore.isFormSubmitted}
                      error={errors.Street}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('EmploymentHistoryForm.street')}</div>
                      </MobxFormLabel>

                      <TextInputWrappingInput
                        id="Street"
                        value={value.Street}
                        onChange={(val) => customerHistoryStore.handleFieldChange('Street', val, index)}
                      />
                    </MobxFieldGroup>

                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={customerHistoryStore.isFormSubmitted}
                      error={errors.District}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('EmploymentHistoryForm.district')}</div>
                      </MobxFormLabel>

                      <TextInputWrappingInput
                        id="District"
                        value={value.District}
                        onChange={(val) => customerHistoryStore.handleFieldChange('District', val, index)}
                      />
                    </MobxFieldGroup>

                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={customerHistoryStore.isFormSubmitted}
                      error={errors.PostTown}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('EmploymentHistoryForm.city_town')}</div>
                      </MobxFormLabel>

                      <TextInputWrappingInput
                        id="PostTown"
                        value={value.PostTown}
                        onChange={(val) => customerHistoryStore.handleFieldChange('PostTown', val, index)}
                      />
                    </MobxFieldGroup>

                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={customerHistoryStore.isFormSubmitted}
                      error={errors.Postcode}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('EmploymentHistoryForm.postcode')}</div>
                      </MobxFormLabel>

                      <TextInputWrappingInput
                        id="Postcode"
                        value={value.Postcode}
                        onChange={(val) => customerHistoryStore.handleFieldChange('Postcode', val, index)}
                      />
                    </MobxFieldGroup>

                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={customerHistoryStore.isFormSubmitted}
                      error={errors.TelephoneNumber}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('EmploymentHistoryForm.telephone_number')}</div>
                      </MobxFormLabel>

                      <PhoneNumberInput
                        id="TelephoneNumber"
                        value={value.TelephoneNumber}
                        onChange={(val) => customerHistoryStore.handleFieldChange('TelephoneNumber', val, index)}
                      />
                    </MobxFieldGroup>

                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={customerHistoryStore.isFormSubmitted}
                      error={errors.TimeAtEmployment && errors.TimeAtEmployment.TotalMonths}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('EmploymentHistoryForm.time_at_employer')}</div>
                      </MobxFormLabel>

                      <YearsMonthsField
                        id="TimeAtEmployment"
                        name="TimeAtEmployment"
                        value={totalMonths}
                        onChange={(val) => customerHistoryStore.handleFieldChangeYM(val, index, 'TimeAtEmployment')}
                      />
                    </MobxFieldGroup>

                    {this.props.applicationFields.GrossAnnual && (
                      <MobxFieldGroup
                        isInline
                        isFormSubmitted={customerHistoryStore.isFormSubmitted}
                        error={errors.GrossAnnual}
                      >
                        <MobxFormLabel>
                          <div>{this.props.t('EmploymentHistoryForm.gross_annual_income')}</div>
                        </MobxFormLabel>

                        <TextInputWrappingInput
                          id="GrossAnnual"
                          suffixIcon="question"
                          size="medium"
                          onChange={(val) => customerHistoryStore.handleFieldChange('GrossAnnual', val, index)}
                          value={value.GrossAnnual}
                          handleModal={() => customerHistoryStore.openModal('modalGross')}
                        />
                      </MobxFieldGroup>
                    )}
                  </div>

                  <div className="employmentHistoryForm__removeOuter">
                    <div className="employmentHistoryForm__remove">
                      {formData.Items.length > 1 && (
                        <Button
                          type="button"
                          buttonStyle="cancel"
                          size="small"
                          key={'remove_' + index}
                          onClick={() => customerHistoryStore.handleRemoveAddress(index, 'TimeAtEmployment')}
                        >
                          {this.props.t('EmploymentHistoryForm.remove')}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <ApplicationCard
                  type="employment"
                  data={value}
                  edit={() => customerHistoryStore.handleEditAddress(index)}
                  remove={() => customerHistoryStore.handleRemoveAddress(index, 'TimeAtEmployment')}
                  errorCount={customerHistoryStore.countErrors(index)}
                  showRemove={formData.Items.length > 1 ? true : false}
                  submitted={formData.formSubmitted}
                  key={'card' + index}
                />
              )}
            </MiniPanel>
          );
        })}

        {customerHistoryStore.showTimeAtAddressWarning && (
          <ExclamationWarningWithButton
            title={this.props.t('EmploymentHistoryForm.thank_you')}
            message={this.props.t('EmploymentHistoryForm.but_you_need_to_give_at_least_3_years_of_employment_history')}
            onClick={() => customerHistoryStore.addItem('Employment')}
            buttonText={this.props.t('EmploymentHistoryForm.add_new_employment')}
          />
        )}

        <FormFooter
          onSave={this.props.onSave && this.onSave}
          onCancel={this.props.onCancel}
          submittingState={this.props.submittingState}
          savingState={this.props.savingState}
        />

        <ModalGross
          isOpen={this.props.appStore.customerHistoryStore.modalOpen === 'modalGross'}
          onClose={this.props.appStore.customerHistoryStore.closeModal}
        />
      </MobxForm>
    );
  }
}

EmploymentHistoryForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  validationRules: PropTypes.object.isRequired,
  savingState: PropTypes.string,
  submittingState: PropTypes.string,
  dealershipId: PropTypes.string,
  quoteId: PropTypes.string,
  quote: PropTypes.object,
  applicationFields: PropTypes.object
};

export default withTranslation('Application')(inject('appStore')(observer(EmploymentHistoryForm)));
