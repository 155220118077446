import React from 'react';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import Label from './Label';
import { ErrorFieldGroupRHF } from './ErrorFieldGroupRHF';
import ButtonInfoIcon from '../Button/ButtonInfoIcon';
import { useTranslation } from 'react-i18next';
import './fieldGroupRhf.scss';

export type FieldGroupRHFProps = {
  label: string | React.ReactNode;
  helperText?: string | null;
  appendOptionalTag?: boolean;
  name: string;
  disabled?: boolean;
  onTooltipClicked?: () => void;
  children: React.ReactNode;
};

export const FieldGroupRHF = ({
  label,
  helperText = null,
  appendOptionalTag = false,
  name,
  onTooltipClicked,
  children
}: FieldGroupRHFProps) => {
  const { getFieldState } = useFormContext();
  const { t } = useTranslation('Common');
  const fieldState = getFieldState(name);
  const { error } = fieldState;

  return (
    <>
      <div className={cn('fieldGroupRhf', 'inline')}>
        {helperText && <p className="fieldGroupRhf__helperText">{helperText}</p>}
        <div className="fieldGroupRhf__label">
          <Label htmlFor={name}>
            {label} {appendOptionalTag && <span>{t('FieldGroupRHF.optional')}</span>}
          </Label>
          {onTooltipClicked && <ButtonInfoIcon onClick={onTooltipClicked} dataThook={name} />}
        </div>
        <div className="fieldGroupRhf__input">{children}</div>
      </div>
      {error && <ErrorFieldGroupRHF>{fieldState.error?.message}</ErrorFieldGroupRHF>}
    </>
  );
};
