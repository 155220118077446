import { useState, useEffect, useCallback } from 'react';

type Tag = 'main' | 'imperfection';
type ImageWithTag = {
  tag: Tag | string;
  fileName: string;
};

const copy = (obj: any) => JSON.parse(JSON.stringify(obj));

const getNewOrder = (array: ImageWithTag[], currentIndex: number, destinationIndex: number) => {
  array.splice(currentIndex, 0, array.splice(destinationIndex, 1)[0]);
  return array;
};

export const useGallery = (remoteMainGallery: ImageWithTag[], remoteImperfectionGallery: ImageWithTag[]) => {
  const [imageCategory, setImageCategory] = useState<{ main: ImageWithTag[]; imperfection: ImageWithTag[] }>(() => ({
    main: copy(remoteMainGallery),
    imperfection: copy(remoteImperfectionGallery)
  }));
  const [hasOrderChanged, setHasOrderChanged] = useState({ main: false, imperfection: false });

  const isMainGallery = (tag: Tag | 'null') => (tag === 'main' || tag === 'null' ? 'main' : 'imperfection');

  const setGallery = useCallback(
    (items: ImageWithTag[], tag: Tag) => {
      setImageCategory((state) => ({
        ...state,
        [isMainGallery(tag)]: items
      }));
    },
    [setImageCategory]
  );

  const discardGalleryOrder = useCallback(
    (tag: Tag) => {
      setHasOrderChanged((state) => ({
        ...state,
        [isMainGallery(tag)]: false
      }));
    },
    [setHasOrderChanged]
  );

  useEffect(() => {
    !hasOrderChanged.main && setGallery(copy(remoteMainGallery), 'main');
    !hasOrderChanged.imperfection && setGallery(copy(remoteImperfectionGallery), 'imperfection');
  }, [setGallery, remoteImperfectionGallery, remoteMainGallery, hasOrderChanged]);

  const handleMoveItem = useCallback(
    ({ sourceId, destinationId, gallery }: { sourceId: string; destinationId: string; gallery: Tag }) => {
      if (!hasOrderChanged[gallery]) {
        setHasOrderChanged((state) => ({ ...state, [gallery]: true }));
      }
      const sourceIndex = imageCategory[gallery].findIndex((item) => item.fileName === sourceId);
      const destinationIndex = imageCategory[gallery].findIndex((item) => item.fileName === destinationId);
      const newOrder = getNewOrder([...imageCategory[gallery]], sourceIndex, destinationIndex);
      setGallery(newOrder, gallery);
    },
    [hasOrderChanged, imageCategory, setGallery]
  );

  return {
    imageCategory,
    hasOrderChanged,
    setGallery,
    discardGalleryOrder,
    handleMoveItem
  };
};
