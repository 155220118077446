import { useTranslation } from 'react-i18next';
import React from 'react';
import './vehiclePanel.scss';
import Table from './SummaryTable/Table';
import TableRow from './SummaryTable/TableRow';
import OuterLeft from './SummaryTable/OuterLeft';
import OuterRight from './SummaryTable/OuterRight';
import { commafy, getOptionKey } from '../../../core/helpers';
import TableHead from './SummaryTable/TableHead';
import TableCell from './SummaryTable/TableCell';
import MoneyFormat from '../../Common/MoneyFormat';
import { CustomerVehicleOfInterest, Vehicle } from '~/types/vehicle';
import { ApplicationQuote, PlatformMetaObject } from '~/types/application';

interface VehiclePanelProps {
  vehicle: CustomerVehicleOfInterest | Vehicle;
  quote?: ApplicationQuote;
}

const VehiclePanel: React.FC<VehiclePanelProps> = ({ vehicle, quote }) => {
  const { t } = useTranslation('Reporting');
  const vin = vehicle?.Vin || ((quote?.PlatformMeta as PlatformMetaObject)?.Vin ?? '');

  return (
    <div className="vehiclePanel" data-th="VehiclePanel">
      <OuterLeft>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('VehiclePanel.make')}</TableHead>
            <TableCell>{quote?.VehicleMake || vehicle.Make}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('VehiclePanel.model')}</TableHead>
            <TableCell>{quote?.VehicleModel || vehicle.Model}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('VehiclePanel.derivative')}</TableHead>
            <TableCell>{quote?.VehicleDerivative || vehicle.Derivative}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('VehiclePanel.date_of_registration')}</TableHead>
            <TableCell>{vehicle.RegistrationDate} </TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('VehiclePanel.vin')}</TableHead>
            <TableCell>{vin}</TableCell>
          </TableRow>
        </Table>
      </OuterLeft>
      <OuterRight>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('VehiclePanel.condition')}</TableHead>
            <TableCell>{vehicle.Condition ? getOptionKey(vehicle.Condition).text : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('VehiclePanel.class')}</TableHead>
            <TableCell>{vehicle.Class ? getOptionKey(vehicle.Class.toLowerCase()).text : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">
              {!quote || quote.VehiclePrice ? t('VehiclePanel.vehicle_price') : t('VehiclePanel.total_price')}
            </TableHead>
            <TableCell>
              <MoneyFormat value={quote ? quote.VehiclePrice || quote.CashPrice : vehicle.CashPrice} />
            </TableCell>
          </TableRow>
          {(quote?.VehicleVrm || vehicle?.Vrm) && (
            <TableRow>
              <TableHead width="50%">{t('VehiclePanel.registration_number')}</TableHead>
              <TableCell>{quote?.VehicleVrm || vehicle?.Vrm}</TableCell>
            </TableRow>
          )}
          {quote?.VehicleMileage ? (
            <TableRow>
              <TableHead width="50%">{t('VehiclePanel.mileage')}</TableHead>
              <TableCell>{commafy(quote.VehicleMileage)}</TableCell>
            </TableRow>
          ) : null}
        </Table>
      </OuterRight>
    </div>
  );
};

export default VehiclePanel;
