import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { growthbook } from '~/index';

export const canSeeConsumerDutyContent = () => growthbook && growthbook.isOn('rtl-enable-consumer-duty');
export const canSeeCommissionDisclosureQuestion = () =>
  growthbook && growthbook.isOn('rtl-enable-commission-disclosure');
// This needs to be a function, so that it evaluates at the point of calling, not when it's imported,
// because when it's imported Growthbook might not have loaded the features yet
export const canSeeCashback = () => growthbook && growthbook.isOn('rtl-show-cashback'); //process.env.REACT_APP_CAN_SEE_CASHBACK === 'true';
export const hasCompanionQuotes = (quote) =>
  growthbook &&
  growthbook.isOn('rtl-show-multi-schedule-quote-layout') &&
  process.env.REACT_APP_MULTI_SCHEDULE_ENABLED_FOR.includes(quote.FunderCode) &&
  quote?.CompanionQuotes !== undefined &&
  quote?.CompanionQuotes !== null &&
  quote?.CompanionQuotes.length > 0;

export const disableGapProducts = () => growthbook && growthbook.isOn('rtl-disable-gap-products');

export const canSeeInterceptor = () => growthbook && growthbook.isOn('rtl-enable-interceptor');

export const canSeeRbpMultilender = () => growthbook && growthbook.isOn('rtl-enable-multilender-rbp');

export const canValidateContactAgainstDob = () =>
  growthbook && growthbook.isOn('rtl-validate-contact-duration-against-dob');

export const canValidatePhoneNumbersWithLibPhoneNumber = () =>
  growthbook && growthbook.isOn('rtl-validate-phone-numbers-with-libphonenumber');

export const canSeeReportingInsights = () => growthbook && growthbook.isOn('rtl-enable-reporting-insights');

export const enableYesNoAffordabilityQuestions = () =>
  growthbook && growthbook.isOn('rtl-enable-yes-no-affordability-question');

export const canSeeHomePage = () => growthbook && growthbook.isOn('rtl-home-page');

export const canSeeRekoSchutzInterestAlert = () => growthbook && growthbook.isOn('rtl-enable-reko-schutz-interest');

export const useRepExampleFeatureIsOn = () => useFeatureIsOn('rtl-rep-example');

export const isMOTQuestionsOn = () =>
  growthbook && growthbook.isOn('rtl-enable-mnf-additional-affordability-questions');

export const canSeePartExchangeAndSettlementInDE = () =>
  growthbook && growthbook.isOn('rtl-part-exchange-and-settlement-in-de');

export const canUseSendDealViaQuoteResultsPage = () =>
  growthbook && growthbook.isOn('rtl-show-send-deal-quote-results-page');

export const canSeeFinanceNavigator = () => growthbook && growthbook.isOn('rtl-enable-finance-navigator');

export const canSeeLongerTermOptions = () => growthbook && growthbook.isOn('rtl-longer-term-option-enabled');

export const canEditPersonalDetailsPostSubmission = () =>
  growthbook && growthbook.isOn('rtl-enable-edit-personal-details-post-application');

export const canSeeRSVVaps = () => growthbook && growthbook.isOn('rtl-enable-rsv-vaps');
