import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import { CustomerType, CustomerTypeEnum } from '~/api/contentService/utils';
import { TFunction } from 'i18next';
import observerForHooks from '~/hocs/observerForHooks';
import Button from '~/components/Common/Button/Button';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PhoneNumberInput } from '~/components/Common/Form/PhoneNumberInput';
import CheckboxWithLabel from '~/components/Common/Form/CheckboxWithLabel';
import AssignLeadContainer from '~/components/AssignLead/containers/AssignLeadContainer';
import FormFooter from '~/components/Common/Form/FormFooter';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useRhfValidationResolver } from '~/components/FinanceNavigator/forms/validation';
import { FieldGroupRHF } from '~/components/Common/Form/FieldGroupRHF';
import Input from '~/components/Common/Form/Input';
import CustomerLookup from '~/components/Application/forms/CustomerLookup/CustomerLookup';
import RadioButtonGroup from '~/components/Common/Form/RadioButtonGroup';
import { FetchedCustomerType } from '~/api/lookups';
import styles from './sendDealForm.module.scss';
import { onCustomerFound } from './utils';

export type SendDealFormValues = {
  // Shared between consumer and corporate customers:
  CustomerLookup: string;
  Id: string;
  CustomerType: CustomerType;
  CustomerName: string;
  Landline: string;
  Mobile: string;
  MarketingOptions: boolean;
  AssignToSelf: boolean;
  AssignToUser: boolean;
  AssignedTo: FetchedCustomerType['AssignedTo'] | [];

  // Only applicable for Consumer/Private customers
  FirstName: string;
  MiddleNames: string;
  Surname: string;
  Email: string;

  // Only applicable for Corporate/Business customers
  TradingName: string;
};

// Will be useful when we are allowing the user to come via the CustomerRecord and need to handle having a customer already selected
type JourneySource = 'QuickQuote' | 'CustomerRecord';
export interface SendDealFormProps {
  customerType: CustomerType;
  quotes: [{}] | [];
  customer: {};
  journeySource: JourneySource;
  onSendDealToNewCustomer: (fields: SendDealFormValues) => void;
  onSendDealToExistingCustomer: (fields: SendDealFormValues) => void;
  onCancel: () => void;
  appStore: any;
  t: TFunction;
}

export const SendDealForm = ({
  customerType,
  onSendDealToNewCustomer = () => {},
  onSendDealToExistingCustomer = () => {},
  onCancel,
  appStore
}: SendDealFormProps) => {
  const { t } = useTranslation('QuickQuote');

  const defaultSendDealFormValues: SendDealFormValues = {
    CustomerLookup: '',
    Id: '',
    CustomerType: customerType,
    CustomerName: '',
    MarketingOptions: false,
    AssignToSelf: false,
    AssignToUser: false,
    FirstName: '',
    MiddleNames: '',
    Surname: '',
    Email: '',
    TradingName: '',
    Landline: '',
    Mobile: '',
    AssignedTo: []
  };

  const resolver = useRhfValidationResolver({});
  const methods = useForm<SendDealFormValues>({
    defaultValues: defaultSendDealFormValues,
    mode: 'all',
    resolver
  });
  const { dealershipId = '' } = useParams<{
    dealershipId: string;
  }>();

  const { canAssign } = appStore.uiState;
  const [isFormExpanded, setIsFormExpanded] = useState(false);
  const [isExistingCustomer, setIsExistingCustomer] = useState(false);
  const { control, watch, formState } = methods;
  const isCorporateCustomer = customerType === CustomerTypeEnum[2];
  const landlineFieldLabel = isCorporateCustomer ? 'SendDealForm.landline' : 'SendDealForm.home_number';
  const assignedTo = watch('AssignedTo');

  const handleSendDeal = (fields: SendDealFormValues) => {
    return isExistingCustomer ? onSendDealToExistingCustomer(fields) : onSendDealToNewCustomer(fields);
  };

  const updateState = (isCustomerFound: boolean) => {
    setIsFormExpanded(isCustomerFound);
    setIsExistingCustomer(isCustomerFound);
  };

  const handleOnCustomerFound = (customer: FetchedCustomerType | null) => {
    onCustomerFound({ customer, methods, updateState, defaultValues: defaultSendDealFormValues });
  };

  return (
    <div className={styles['container']}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSendDeal)} data-th="sendDealForm">
          <section className={styles['upper']}>
            <Controller
              name="CustomerLookup"
              control={control}
              render={({ field }) => (
                <FieldGroupRHF label={undefined} name={field.name}>
                  <CustomerLookup
                    restrictToCustomerVariant={customerType}
                    value={field.value}
                    onChange={(email) => {
                      field.onChange(email);
                    }}
                    onCustomerFound={handleOnCustomerFound}
                  />
                </FieldGroupRHF>
              )}
            />

            <p className={styles['dividingText']}>{t('SendDealForm.or')}</p>
            <Button
              buttonStyle="secondary"
              onClick={() => setIsFormExpanded(!isFormExpanded)}
              dataThook="addCustomerContact"
            >
              {t('SendDealForm.add_customer_contact')}
            </Button>
          </section>

          {isFormExpanded && (
            <>
              <section className={styles['customerDetails']} data-th="customerDetails">
                <Controller
                  name="CustomerType"
                  control={control}
                  render={({ field }) => (
                    <FieldGroupRHF label={t('SendDealForm.customer_type')} name={field.name}>
                      <RadioButtonGroup
                        options={[
                          {
                            key: 'consumer',
                            value: t('SendDealForm.consumer', {
                              ns: 'QuickQuote'
                            })
                          },
                          {
                            key: 'corporate',
                            value: t('SendDealForm.corporate', {
                              ns: 'QuickQuote'
                            })
                          }
                        ]}
                        name="CustomerType"
                        isGroupDisabled={true}
                        onChange={field.onChange}
                        checkedValue={field.value}
                        variant="inline"
                      />
                    </FieldGroupRHF>
                  )}
                />
                {!isCorporateCustomer && (
                  <>
                    <Controller
                      name="FirstName"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FieldGroupRHF
                          label={t('SendDealForm.first_name')}
                          helperText={t('SendDealForm.enter_name_as_it_appears')}
                          name={field.name}
                        >
                          <Input
                            id={field.name}
                            onChange={field.onChange}
                            value={field.value}
                            onBlur={field.onBlur}
                            isInvalid={fieldState.invalid}
                          />
                        </FieldGroupRHF>
                      )}
                    />
                    <Controller
                      name="MiddleNames"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FieldGroupRHF label={t('SendDealForm.middle_name')} name={field.name} appendOptionalTag>
                          <Input
                            id={field.name}
                            onChange={field.onChange}
                            value={field.value}
                            onBlur={field.onBlur}
                            isInvalid={fieldState.invalid}
                          />
                        </FieldGroupRHF>
                      )}
                    />
                    <Controller
                      name="Surname"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FieldGroupRHF label={t('SendDealForm.last_name')} name={field.name}>
                          <Input
                            id={field.name}
                            onChange={field.onChange}
                            value={field.value}
                            onBlur={field.onBlur}
                            isInvalid={fieldState.invalid}
                          />
                        </FieldGroupRHF>
                      )}
                    />
                    <Controller
                      name="Email"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FieldGroupRHF label={t('SendDealForm.email')} name={field.name}>
                          <Input
                            id={field.name}
                            onChange={field.onChange}
                            value={field.value}
                            onBlur={field.onBlur}
                            isInvalid={fieldState.invalid}
                            disabled={isExistingCustomer}
                          />
                        </FieldGroupRHF>
                      )}
                    />
                  </>
                )}
                {isCorporateCustomer && (
                  <>
                    <Controller
                      name="TradingName"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FieldGroupRHF
                          label={t('SendDealForm.trading_name')}
                          helperText={t('SendDealForm.enter_name_as_it_appears')}
                          name={field.name}
                        >
                          <Input
                            id={field.name}
                            onChange={field.onChange}
                            value={field.value}
                            onBlur={field.onBlur}
                            isInvalid={fieldState.invalid}
                          />
                        </FieldGroupRHF>
                      )}
                    />
                  </>
                )}
                <Controller
                  name="Landline"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FieldGroupRHF label={t(`${landlineFieldLabel}`)} name={field.name}>
                      {/* @ts-ignore - missing properties */}
                      <PhoneNumberInput
                        id={field.name}
                        value={field.value || ''}
                        onChange={field.onChange}
                        isInvalid={fieldState.invalid}
                        onBlur={field.onBlur}
                      />
                    </FieldGroupRHF>
                  )}
                />
                <Controller
                  name="Mobile"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FieldGroupRHF label={t('SendDealForm.mobile_number')} name={field.name}>
                      {/* @ts-ignore - missing properties */}
                      <PhoneNumberInput
                        id={field.name}
                        value={field.value || ''}
                        onChange={field.onChange}
                        isInvalid={fieldState.invalid}
                        onBlur={field.onBlur}
                      />
                    </FieldGroupRHF>
                  )}
                />
              </section>
              <section className={styles['customerConfirm']} data-th="customerConfirm">
                <div data-th="transactionalCommunications">
                  <h4>{t('SendDealForm.transactional_communications')}</h4>
                  <p>{t('SendDealForm.transactional_communications_required')}</p>
                </div>

                <div>
                  <Controller
                    name="MarketingOptions"
                    control={control}
                    render={({ field }) => (
                      <FieldGroupRHF label={t('SendDealForm.marketing_options')} name={field.name} appendOptionalTag>
                        <CheckboxWithLabel
                          value={field.value}
                          name="MarketingOptions"
                          id={field.name}
                          onChange={field.onChange}
                          dataThook="marketingOptions"
                        >
                          {t('SendDealForm.marketing_options_text')}
                        </CheckboxWithLabel>
                      </FieldGroupRHF>
                    )}
                  />
                </div>

                {canAssign && (
                  <div className={styles['assignOptions']}>
                    <Controller
                      name="AssignToSelf"
                      control={control}
                      render={({ field }) => (
                        <FieldGroupRHF label="Assign customer to you or another dealership user" name={field.name}>
                          <div className={styles['assignOptions']}>
                            <CheckboxWithLabel
                              value={field.value}
                              name="AssignToSelf"
                              id={field.name}
                              onChange={field.onChange}
                              dataThook="AssignToSelf"
                            >
                              {t('SendDealForm.assign_to_me')}
                            </CheckboxWithLabel>

                            <div className={styles['assignToUser']}>
                              {assignedTo && !assignedTo.length && (
                                <div>{t('SendDealForm.assign_to_dealership_user')}</div>
                              )}
                              <AssignLeadContainer
                                dealershipId={dealershipId}
                                customerId={methods.getValues('Id')}
                                customerName={methods.getValues('FirstName') || methods.getValues('TradingName')}
                                customerType={customerType}
                                initialAssignees={assignedTo}
                              />
                            </div>
                          </div>
                        </FieldGroupRHF>
                      )}
                    />
                  </div>
                )}
              </section>
              <FormFooter
                submitLabel={
                  isExistingCustomer ? t('SendDealForm.continue') : t('SendDealForm.add_customer_and_continue')
                }
                submitButtonTag={isExistingCustomer ? 'continue' : 'addCustomerAndContinue'}
                submitDisabled={!formState.isValid}
                hideSubmit={false}
                submittingState=""
                cancelLabel={t('SendDealForm.cancel')}
                onCancel={onCancel}
              />
            </>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default inject('appStore')(observerForHooks(SendDealForm));
