import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { inject, observer } from 'mobx-react';
import { makeMonthOptions } from '~/redux/options/options.factory';

import './selectInput.scss';

export const termsLookup = new Set([
  'Terms',
  'TermsExtended',
  'CloseDealTerms',
  'PreferredTerm',
  'PreferredTermExtended',
  'GermanTerms'
]);

export function setFilteredOptionsList(hasEditLimit, selectOptions) {
  let filteredselectOptions = selectOptions;

  if (hasEditLimit) {
    filteredselectOptions = _.filter(filteredselectOptions, (o) => {
      return o.key <= hasEditLimit;
    });
  }

  return filteredselectOptions;
}

const SelectInput = ({
  t,
  appStore,
  emptyValue,
  optionsList,
  options,
  isValid,
  isInvalid,
  showError,
  id,
  value,
  onChange,
  onBlur,
  disabled,
  size,
  trackingPage,
  hasEditLimit,
  dataThook,
  extraOption,
  funderCode
}) => {
  let selectOptions = [];

  if (emptyValue) {
    selectOptions.push({ key: '', value: t('SelectInput.please_select') });
  }

  if (extraOption) {
    selectOptions.push(extraOption);
  }

  if (termsLookup.has(options) && appStore.uiState.isDe) {
    options = 'GermanTerms';
  }

  let globalOptions = optionsList[options];

  const overrideOptions = optionsList.Overrides[funderCode]?.[options];

  /*
   * Tactical fix for MNF
   * MNF explicitly require the term options to be different to everyone else
   * This is an agreed, short-term solution and it is to be removed as part of RTL-2615 later in 2025
   */
  if (appStore.uiState.isMotonovo && options === 'Terms') {
    globalOptions = makeMonthOptions(12, 61);
  }
  if (appStore.uiState.isMotonovo && options === 'PreferredTerm') {
    globalOptions = makeMonthOptions(12, 61, 6);
  }
  /* End of fix */

  selectOptions = selectOptions.concat(overrideOptions || globalOptions);

  const selectStyles = classnames(
    'selectInput',
    (!isValid || isInvalid) && showError && 'selectInput--invalid',
    size === 'small' && 'selectInput--small'
  );

  const dataAttrs = {};

  if (isValid === false) {
    dataAttrs['data-is-invalid'] = true;
  }

  selectOptions = setFilteredOptionsList(hasEditLimit, selectOptions);

  return (
    <select
      data-th={dataThook ? `${dataThook}Select` : `${id}Select`}
      className={selectStyles}
      id={id}
      value={value}
      onChange={(e) => onChange(id, e.currentTarget.value)}
      onBlur={(e) => {
        onBlur && onBlur(id, e.currentTarget.value);
        trackingPage && window.ga && window.ga('send', 'event', trackingPage, 'FieldBlur', id);
      }}
      disabled={disabled}
      {...dataAttrs}
    >
      {selectOptions.map((option) => (
        <option key={option.key} value={option.key}>
          {option.value}
        </option>
      ))}
    </select>
  );
};

SelectInput.defaultProps = {
  emptyValue: true
};

SelectInput.propTypes = {
  options: PropTypes.string.isRequired,
  optionsList: PropTypes.object.isRequired,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  showError: PropTypes.bool,
  emptyValue: PropTypes.bool,
  size: PropTypes.string,
  trackingPage: PropTypes.string,
  hasEditLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataThook: PropTypes.string,
  appStore: PropTypes.object
};

function mapStateToProps(state) {
  return {
    optionsList: state.options
  };
}

export default withTranslation('Common')(connect(mapStateToProps)(inject('appStore')(observer(SelectInput))));
