import { useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useParams } from 'react-router-dom';
import { useDebounce } from '@react-hook/debounce';
import { useLookupByCustomerDetails } from '~/api/lookups/hooks';
import { FetchedCustomerType, formatCustomerDataToSelectOptions } from '~/api/lookups';
import { useTranslation } from 'react-i18next';
import { StylesConfig } from 'react-select';
import { CustomerType, CustomerTypeEnum } from '~/api/contentService/utils';

const customStyles: StylesConfig<AsyncSelectOption, boolean> = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    fontFamily: 'Open Sans, Arial, sans-serif'
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#d3d3d3'
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '10px',
    lineHeight: '24px'
  }),
  option: (provided) => ({
    ...provided,
    marginBottom: '10px',
    borderRadius: '0px',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#f0f0ed'
    },
    '&:first-of-type': {
      borderRadius: '6px 6px 0px 0px'
    },
    '&:last-of-type': {
      borderRadius: '0px 0px 6px 6px',
      marginBottom: '0px'
    },
    '.customerLookup__label': {
      fontSize: '12px',
      p: {
        '&:first-of-type': {
          fontWeight: 'bold',
          fontSize: '14px'
        }
      }
    }
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '1px solid #055bd4' : '1px solid #d3d3d3',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #055bd4'
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#4A4A4A',
    textAlign: 'center',
    fontSize: '12px'
  }),
  loadingMessage: (provided) => ({
    ...provided,
    color: '#4A4A4A',
    fontSize: '12px'
  }),
  singleValue: (provided) => ({
    ...provided,
    '.customerLookup__label': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '6px',
      fontSize: '12px',
      p: {
        '&:first-of-type': {
          fontWeight: 'bold',
          fontSize: '14px'
        }
      }
    }
  })
};

const formatOptionLabel = (option: AsyncSelectOption) => {
  const customer = option.value;
  if (!customer) {
    return null;
  }
  const { CustomerType } = customer;
  const isConsumer = CustomerType === CustomerTypeEnum.consumer;

  return (
    <div className="customerLookup__label">
      <p>{customer?.CustomerName}</p>
      {isConsumer && <span>{customer?.Consumer?.Email}</span>}
    </div>
  );
};

type CustomerLookupProps = {
  restrictToCustomerVariant?: CustomerType | '';
  value: string;
  onChange?: (email: string) => void;
  onBlur?: () => void;
  autoComplete?: string;
  placeholder?: string;
  onCustomerFound: (customer: FetchedCustomerType | null) => void;
};

type AsyncSelectOption = {
  label: string;
  value: FetchedCustomerType;
};

const CustomerLookup = ({
  restrictToCustomerVariant = '',
  onChange,
  onBlur,
  placeholder,
  onCustomerFound
}: CustomerLookupProps) => {
  const { t } = useTranslation('Application');
  const { dealershipId = '' } = useParams<{ dealershipId: string }>() || { dealershipId: '' };
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [debouncedValue, setDebouncedValue] = useDebounce('', 300);
  const deferredLoadOptionsResolver = useRef<(data: AsyncSelectOption[]) => void>(() => {});

  const { isLoading, data } = useLookupByCustomerDetails({
    dealershipId,
    debouncedValue,
    customerType: restrictToCustomerVariant
  });

  const loadOptions = async (input: string) =>
    new Promise<AsyncSelectOption[]>((resolve) => {
      setDebouncedValue(input);
      deferredLoadOptionsResolver.current = resolve;
    });

  useEffect(() => {
    if (!isLoading && data?.Results && data?.Results.length > 0) {
      const formattedCustomers = formatCustomerDataToSelectOptions(data, restrictToCustomerVariant);
      deferredLoadOptionsResolver.current(formattedCustomers);
    }
  }, [data, restrictToCustomerVariant, isLoading]);

  const handleChange = (selectedOption: any) => {
    setSelectedCustomer(selectedOption);
    onChange?.(selectedOption?.value?.Email || '');
    onCustomerFound(selectedOption?.value);
  };

  return (
    <AsyncSelect
      classNamePrefix="customerLookup"
      aria-label="Existing Customer Lookup"
      cacheOptions
      // @ts-ignore
      loadOptions={loadOptions}
      loadingMessage={() => t('CustomerLookup.loading')}
      defaultOptions={undefined}
      onBlur={onBlur || undefined}
      onChange={handleChange}
      placeholder={placeholder || t('CustomerLookup.search_for_customers_email')}
      value={selectedCustomer}
      isClearable
      openMenuOnClick={false}
      styles={customStyles}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

export default CustomerLookup;
