import QuoteCommitmentMessage from '../QuoteCommitmentMessage';
import AnnualRateAdjustedMessage from '../AnnualRateAdjustedMessage';
import { connect } from 'react-redux';
import QuoteDisclaimer from '~/components/QuoteCard/components/QuoteDisclaimer';
import { inject } from 'mobx-react';
import { formatAddress } from '~/core/helpers';
import { useTranslation } from 'react-i18next';
import { FunderType, QuoteVariants, QuoteSummaryVariantTypeEnum, QuoteTypes } from '~/components/QuoteCard/types';
import './quoteLegalText.scss';

interface QuoteLegalTextProps {
  quote: QuoteTypes;
  variant?: QuoteVariants;
  funders: FunderType[];
  appStore?: any;
  isPreApproved?: boolean;
}

const QuoteLegalText: React.FC<QuoteLegalTextProps> = ({
  quote,
  variant,
  funders,
  appStore,
  isPreApproved = false
}) => {
  const { t } = useTranslation('Common');

  const { FunderCode, FinanceType } = quote;
  const { isBdk } = appStore.uiState;

  const isMOTQuote = FunderCode === 'MOT';
  const isPCPQuote = FinanceType === 'PCP';
  const funder = funders && (funders?.find((funder) => funder.Code === quote.FunderCode) as FunderType);

  const printPageDisclaimers = [
    { key: 'QuoteLegalText.fees_disclaimer' },
    { key: 'QuoteLegalText.mot_pcp_disclaimer', condition: isPCPQuote && isMOTQuote },
    { key: 'QuoteLegalText.indemnity_disclaimer' },
    { key: 'QuoteLegalText.assumptions_disclaimer', additionalContent: <QuoteCommitmentMessage /> },
    { key: 'QuoteLegalText.subject_to_status_disclaimer' },
    {
      key: 'QuoteLegalText.reference',
      condition: quote?.QuoteeProductCode,
      additionalContent: quote?.QuoteeProductCode
    },
    {
      key: 'QuoteLegalText.finance_is_provided_by',
      condition: funder,
      additionalContent: formatAddress(funder)
    }
  ];

  return (
    <div>
      {isMOTQuote ? (
        <span>{t('QuoteLegalText.moto_novo_finance_limited')}</span>
      ) : (
        <QuoteDisclaimer funder={funder} isBdk={isBdk} isPreApproved={isPreApproved} />
      )}
      {!isBdk && !isPreApproved && <QuoteCommitmentMessage />}

      {isPCPQuote && isMOTQuote && (
        <span>
          <br />* <AnnualRateAdjustedMessage />
        </span>
      )}

      {isMOTQuote && <span>{t('QuoteLegalText.we_will_receive_a_commission')}</span>}

      {variant === QuoteSummaryVariantTypeEnum.SavedQuoteSummary && (
        <div className="quoteLegalText--print">
          {printPageDisclaimers.map((disclaimer) =>
            disclaimer.condition === false ? null : (
              <p key={disclaimer.key}>
                {t(disclaimer.key)}
                {disclaimer.additionalContent}
              </p>
            )
          )}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    funders: state.funders
  };
}

export default connect(mapStateToProps)(inject('appStore')(QuoteLegalText));
