import { action, observable, toJS } from 'mobx';
import { fetchVehicleData } from '../api/stockModule';
import * as debug from '../debug';
import { delay } from '../core/helpers';

class StockEditStore {
  @observable
  stockNotification = [];

  epochTimestamp = null;

  @observable
  editFormData = {};

  @observable
  loadingState = 'loading';

  @observable
  vehicleData = null;

  vehicleTaxonomyData = null;

  @action
  setVehicleTaxonomyData = (vehicleTaxonomyData) => {
    this.vehicleTaxonomyData = vehicleTaxonomyData;
  };

  @action
  setLoadingState(state) {
    this.loadingState = state;
  }

  @action
  setVehicleImages = (images, gallery = 'main') => {
    if (gallery === 'imperfection') {
      this.vehicleData = {
        ...this.vehicleData,
        imprfImgUrls: images.map(({ fileName }) => fileName)
      };
    } else {
      this.vehicleData = {
        ...this.vehicleData,
        imgUrls: images.map(({ fileName }) => fileName)
      };
    }
  };

  @action
  clearStore = () => {
    this.vehicleData = null;
    this.epochTimestamp = null;
    this.loadingState = 'loading';
    this.editFormData = {};
    this.vehicleTaxonomyData = null;
  };

  @action
  setVehicleData(data) {
    this.vehicleData = data;
  }
  getVehicleData = async (dealershipId, vehicleId) => {
    try {
      this.setLoadingState('loading');
      const vehicleData = await fetchVehicleData({ dealershipId, vehicleId });
      this.setVehicleData(vehicleData);
      this.setLoadingState('idle');
    } catch (e) {
      this.setLoadingState('error');
    }
  };

  syncUploadedImageData = async (fileNames, dealershipId, vehicleId) => {
    let retries = 0;

    try {
      while (retries <= 20) {
        const vehicleData = await fetchVehicleData({ dealershipId, vehicleId });
        const allImagesPresent = this.haveAllImagesBeenUploaded(fileNames, vehicleData.imagesMetadata);

        if (allImagesPresent) {
          this.setVehicleData(vehicleData);
          this.epochTimestamp = vehicleData.epochTimestamp;
          return;
        }

        retries += 1;
        await delay(1000);
      }

      throw new Error('Max retries reached');
    } catch (e) {
      debug.error(e);
    }
  };

  @action
  setEditFormData = (name, value) => {
    this.editFormData = {
      ...this.editFormData,
      [name]: value
    };
  };

  // helpers
  haveAllImagesBeenUploaded = (expected, uploaded) => {
    return expected.every((expectedFilename) =>
      uploaded.some((uploadedImage) => uploadedImage.fileName === expectedFilename)
    );
  };
}

export default StockEditStore;
