import { TFunction } from 'i18next';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Button from '~/components/Common/Button/Button';
import ToggleSwitch from '~/components/Common/ToggleSwitch';
import observerForHooks from '~/hocs/observerForHooks';
import './quoteResultsToolbar.scss';
import { CustomerType, Customer } from '~/api/contentService/utils';

export interface QuoteResultsToolbarProps {
  t: TFunction;
  customerType: CustomerType;
  onEditQuote: () => void;
  onViewAllQuotes: () => void;
  showAllQuotes: boolean;
  hasCheckedEligibility: boolean;
  onCheckEligibility: () => void;
  enableSendDeal: boolean;
  onSendDeal: () => void;
  onToggleShowSchemes: () => void;
  showSchemes: boolean;
  onToggleShowFullQuotes: () => void;
  showFullQuote: boolean;
  onCompareQuotes: () => void;
  hasQuoteSelected: boolean;
  appStore: {
    uiState: {
      isBdk: boolean;
      canUseShowroomCfc: boolean;
    };
  };
}

const QuoteResultsToolbar = (props: QuoteResultsToolbarProps) => {
  const {
    customerType,
    onEditQuote,
    onViewAllQuotes,
    showAllQuotes,
    hasCheckedEligibility,
    onCheckEligibility,
    enableSendDeal: canDisplaySendDealBtn,
    onSendDeal,
    onToggleShowSchemes,
    showSchemes,
    onToggleShowFullQuotes,
    showFullQuote,
    onCompareQuotes,
    hasQuoteSelected,
    appStore
  } = props;

  const { t } = useTranslation('Quoting');

  const { isBdk, canUseShowroomCfc } = appStore.uiState;
  const viewQuotesBtnText = showAllQuotes ? t('QuoteResults.less_quotes') : t('QuoteResults.more_quotes');
  const isConsumer = customerType && customerType.toLowerCase() === Customer.Consumer;

  const canDisplayCheckEligibilityBtn = canUseShowroomCfc && isConsumer && !hasCheckedEligibility;
  const canDisplayTogglesAndCompareQuotesBtn = !isBdk;

  return (
    <section className="quoteResultsToolbar" data-th="quoteResultsToolbar">
      <div className="quoteResultsToolbar__buttons">
        <Button buttonStyle="secondary" type="button" onClick={onEditQuote} id="EditQuotes" dataThook="EditQuotes">
          {t('QuoteResults.edit_quotes')}
        </Button>
        <Button buttonStyle="secondary" type="button" onClick={onViewAllQuotes} id="MoreQuotes" dataThook="MoreQuotes">
          {viewQuotesBtnText}
        </Button>
        {canDisplayCheckEligibilityBtn && (
          <Button
            buttonStyle="secondary"
            type="button"
            onClick={onCheckEligibility}
            id="CheckEligibility"
            dataThook="CheckEligibility"
          >
            {t('QuoteResults.check_eligibility')}
          </Button>
        )}

        {canDisplayTogglesAndCompareQuotesBtn && (
          <Button
            buttonStyle="select"
            type="button"
            disabled={!hasQuoteSelected}
            onClick={onCompareQuotes}
            id="PricePosition"
            dataThook="PricePosition"
          >
            {t('QuoteResults.price_position')}
          </Button>
        )}
        {canDisplaySendDealBtn && (
          <Button
            buttonStyle="select"
            type="button"
            disabled={!hasQuoteSelected}
            onClick={onSendDeal}
            id="Send Deal"
            dataThook="SendDeal"
          >
            {t('QuoteResults.send_deal')}
          </Button>
        )}
      </div>

      {canDisplayTogglesAndCompareQuotesBtn && (
        <div className="quoteResultsToolbar__toggles">
          <div>
            <span>{t('QuoteResults.show_schemes')}</span>
            <ToggleSwitch
              id="showSchemes"
              dataThook="ShowSchemes"
              isActive={showSchemes}
              handleUpdate={onToggleShowSchemes}
              showToggleText={false}
            />
          </div>
          <div>
            <span>{t('QuoteResults.show_full_quotes')}</span>
            <ToggleSwitch
              id="showFullQuote"
              dataThook="ShowFullQuote"
              isActive={showFullQuote}
              handleUpdate={onToggleShowFullQuotes}
              showToggleText={false}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default inject('appStore')(observerForHooks(QuoteResultsToolbar));
