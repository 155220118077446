import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.scss';
import classnames from 'classnames';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || this.props.defaultValue
    };
  }

  handleChange = () => {
    this.props.onChange && this.props.onChange(!this.props.value);
    if (this.props.trackingPage && window.ga && this.props.id) {
      window.ga('send', 'event', this.props.trackingPage, 'ButtonPress', this.props.id);
    }
  };

  handleFocus = (e) => {
    this.setState({ hasFocus: true });
    this.props.onFocus && this.props.onFocus(e);
  };

  handleBlur = (e) => {
    this.setState({ hasFocus: false });
    this.props.onBlur && this.props.onBlur(e);
  };

  render() {
    const { id = '', value, errorMessage, formSubmitted, dataThook = '', ...remainingProps } = this.props;
    let checkboxClasses = classnames('checkbox', value && 'checkbox__checked');

    let inputClasses = classnames('checkbox__input', this.props.disabled && 'checkbox__disabled');

    return (
      <div className="checkbox__container">
        <span className={checkboxClasses} />
        <input
          {...remainingProps}
          type="checkbox"
          className={inputClasses}
          checked={value}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          aria-label={id || dataThook}
          data-th={dataThook ? `${dataThook}Checkbox` : 'Checkbox'}
        />
      </div>
    );
  }
}

Checkbox.propTypes = {
  defaultValue: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  formSubmitted: PropTypes.bool,
  trackingPage: PropTypes.string,
  id: PropTypes.string,
  dataThook: PropTypes.string
};

export default Checkbox;
