import parse from 'html-react-parser';
import { connect } from 'react-redux';
import { useFundersContent } from '~/api/contentService/hooks';
import { getFundersContentFilters } from '~/api/contentService/utils';
import { getCommissionDisclosureQuestion } from '~/api/suitability/config';
import { FunderType, VehicleLoanDataType } from '~/components/QuoteCard/types';
import { SessionDealership } from '~/types/dealership';
import './commissionDisclosurePanel.scss';

type CommissionDisclosurePanelProps = {
  quote: VehicleLoanDataType;
  dealership: SessionDealership;
  funders: FunderType[];
};
const CommissionDisclosurePanel = ({ quote, funders, dealership }: CommissionDisclosurePanelProps) => {
  const { data: fundersContent } = useFundersContent(getFundersContentFilters(quote?.FunderCode));
  const funder = funders && (funders.find((funder: FunderType) => funder.Code === quote.FunderCode) as FunderType);

  const commissionDisclosureQuestion = getCommissionDisclosureQuestion({
    quote,
    dealership,
    funder,
    fiduciaryText: fundersContent?.commissionDisclosureShort || '',
    asQuestion: false
  });

  return <div className="commissionDisclosurePanel">{parse(commissionDisclosureQuestion.questionText)}</div>;
};

function mapStateToProps(state: any) {
  return {
    funders: state.funders,
    dealership: state.session.Dealerships.find((dealership: any) => dealership.Id === state.dealership.Id)
  };
}

export default connect(mapStateToProps)(CommissionDisclosurePanel);
