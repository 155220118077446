import platformApiUtils from '@ivendi/platform-api-utils';
import { z, ZodError } from 'zod';
import * as debug from '../../debug';
import { CustomerType, CustomerTypeEnum } from '../contentService/utils';
import { mapCustomerTypeToNumber } from '../utils';

/* SCHEMAS START */

export const LookupByEmailResponseSchema = z.object({
  Exists: z.boolean(),
  DealershipConsumer: z
    .object({
      Id: z.string()
    })
    .nullable()
});

// below schemas based on the response from the /Customer API endpoint
const AssignedLeadSchema = z.object({
  UserId: z.string().uuid(),
  Firstname: z.string(),
  Surname: z.string(),
  Avatar: z.object({
    Colour: z.number()
  })
});

export const CorporateSchema = z.object({
  TradingName: z.string().nullable(),
  Landline: z.string().nullable(),
  Mobile: z.string().nullable()
});

export const ConsumerSchema = CorporateSchema.omit({ TradingName: true }).extend({
  Title: z.string().nullable(),
  Firstname: z.string().nullable(),
  Middlenames: z.string().nullable(),
  Surname: z.string().nullable(),
  Email: z.string().nullable(),
  AliasCount: z.number()
});

export const CustomerSchema = z.object({
  CustomerType: z.number(),
  CustomerName: z.string().nullable(),
  Id: z.string(),
  Consumer: ConsumerSchema.nullable(),
  Corporate: CorporateSchema.nullable(),
  IsAssigned: z.boolean(),
  AssignedTo: z.array(AssignedLeadSchema).nullable()
});

export const LookupByCustomerDetailsResponseSchema = z.object({
  Results: z.array(CustomerSchema)
});

/* SCHEMAS END */

/* TYPES START */
export type LookupByEmailResponse = z.infer<typeof LookupByEmailResponseSchema>;

export type AsyncSelectOption = {
  label: string;
  value: FetchedCustomerType;
};
export type LookupByCustomerDetailsResponseType = z.infer<typeof LookupByCustomerDetailsResponseSchema>;
export type FetchedCustomerType = z.infer<typeof CustomerSchema>;
export type FetchedConsumerDetailsType = z.infer<typeof ConsumerSchema>;

/* TYPES END */

/* API REQUESTS START */

export function lookupByEmail({
  email,
  dealershipId,
  sector = 'residential'
}: {
  email: string;
  dealershipId: string;
  sector: 'corporate' | 'residential';
}) {
  let endpoint = sector === 'corporate' ? 'v1/CorporateCustomer/email' : 'v1/dealershipconsumer/email';

  return platformApiUtils
    .get<LookupByEmailResponse>(`${endpoint}/${encodeURIComponent(email)}`, { dealershipId })
    .then((response) => {
      const parsedResponse = LookupByEmailResponseSchema.parse(response);

      if (parsedResponse?.Exists) {
        return response.DealershipConsumer;
      } else {
        return null;
      }
    })
    .catch((e: any) => {
      debug.error(e);
      return null;
    });
}

export function lookupCustomerByEmail(email: string, dealershipId: string) {
  return new Promise((resolve, reject) => {
    platformApiUtils
      .get(`v1/dealershipconsumer/email/${encodeURIComponent(email)}`, { dealershipId })
      .then((response: any) => {
        if (response?.Exists) {
          resolve(response.DealershipConsumer);
        } else {
          debug.error(`lookupCustomerByEmail`);
          reject();
        }
      })
      .catch((e) => {
        debug.error(e);
        reject();
      });
  });
}

export function lookupCorporateCustomerByEmail(email: string, dealershipId: string) {
  return new Promise((resolve, reject) => {
    platformApiUtils
      .get(`v1/CorporateCustomer/email/${encodeURIComponent(email)}`, { dealershipId })
      .then((response: any) => {
        if (response?.Exists) {
          resolve(response.DealershipConsumer);
        } else {
          reject();
        }
      })
      .catch((e) => {
        debug.error(e);
        reject();
      });
  });
}

export function lookupCorporateCustomerByTradingName(tradingName: string, dealershipId: string) {
  return new Promise((resolve, reject) => {
    platformApiUtils
      .get(`v1/CorporateCustomer/tradingName/${encodeURIComponent(tradingName)}`, { dealershipId })
      .then((response: any) => {
        if (response?.Exists) {
          resolve(response.DealershipConsumer);
        } else {
          reject();
        }
      });
  });
}

export function lookupProductName(name: string, dealershipId: string) {
  return new Promise((resolve, reject) => {
    platformApiUtils.get(`v1/DealershipSettings/${dealershipId}/vap/name-exists`, { name }).then((response: any) => {
      if (response?.Exists) {
        resolve(true);
      } else {
        reject();
      }
    });
  });
}

export type LookupByCustomerDetailsParams = {
  dealershipId: string;
  debouncedValue: string;
  customerType: CustomerType | '';
};
export const lookupByCustomerDetails = async ({
  dealershipId,
  debouncedValue,
  customerType
}: LookupByCustomerDetailsParams): Promise<LookupByCustomerDetailsResponseType> => {
  let lookupUrl = `v1/Customers/${dealershipId}?q=${debouncedValue}`;

  if (customerType) {
    lookupUrl += `&CustomerType=${mapCustomerTypeToNumber(customerType)}`;
  }
  try {
    const data = await platformApiUtils.get(lookupUrl);
    return LookupByCustomerDetailsResponseSchema.parse(data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new Error('Data parsing error');
    } else {
      throw new Error('Fetch error occurred');
    }
  }
};

export const formatCustomerDataToSelectOptions = (
  data: { Results: FetchedCustomerType[] },
  customerType: CustomerType | ''
): AsyncSelectOption[] => {
  /* Helper function to generate the label based on customer type
     due to differing data between consumer and corporate customers */
  const generateLabel = (customer: FetchedCustomerType): string => {
    if (customer.CustomerType === CustomerTypeEnum.consumer) {
      return `${customer.Consumer?.Firstname} ${customer.Consumer?.Surname} (${customer.Consumer?.Email})`;
    } else if (customer.CustomerType === CustomerTypeEnum.corporate) {
      return `${customer.Corporate?.TradingName}`;
    }
    return '';
  };

  return data.Results.map((customer: FetchedCustomerType) => {
    const label = generateLabel(customer);
    if (label && (!customerType || customer.CustomerType === mapCustomerTypeToNumber(customerType))) {
      return {
        label,
        value: customer
      };
    }
    return null;
  }).filter((customer): customer is AsyncSelectOption => customer !== null);
};
/* API REQUESTS END */
