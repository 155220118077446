import { Customer, CustomerType, CustomerTypeEnum } from './contentService/utils';

export const getStatus = (error: any) => {
  if (error instanceof Response) {
    return error.status;
  }

  if (!isNaN(Number(error?.status))) {
    return Number(error.status);
  }

  return null;
};

export const mapCustomerTypeToNumber = (customerType: CustomerType | string): CustomerTypeEnum | null => {
  switch (customerType.toLowerCase()) {
    case Customer.Consumer:
      return CustomerTypeEnum.consumer;
    case Customer.Corporate:
      return CustomerTypeEnum.corporate;
    default:
      return null;
  }
};

export const mapNumberToCustomerType = (customerTypeNumber: CustomerTypeEnum): CustomerType | null => {
  switch (customerTypeNumber) {
    case CustomerTypeEnum.consumer:
      return Customer.Consumer;
    case CustomerTypeEnum.corporate:
      return Customer.Corporate;
    default:
      return null;
  }
};
