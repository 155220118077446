import _ from 'lodash';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../Common/Modal/Modal';
import SendDealModalContents from '../components/SendDealModalContents';
import * as modalActions from '../../../redux/modal/modalActions';
import * as sendDealActions from '../../../redux/sendDeal/sendDealActions';
import { getCustomer } from '../../../selectors/customerSelectors';
import { push } from '../../../routerHistory';
import { objectToSearchParams } from 'utils/objectToSearchParams';
import SendDealForm from '~/components/QuickQuote/forms/SendDealForm/SendDealForm';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { inject, observer } from 'mobx-react';
import { canUseSendDealViaQuoteResultsPage } from '~/features';

const SendDealModalContainer = (props) => {
  const {
    isQuickQuote,
    sendDealFromQuickQuote,
    sendDeal,
    currentView = '',
    isOpen,
    quotes = [],
    navigateToCustomerRecord,
    dealershipId,
    returnedCustomerId,
    returnedVehicleId,
    onQuickQuoteProceed,
    existingCustomer,
    isExistingCustomer,
    customer,
    sendDealToExistingCustomerFromQuickQuote,
    customerType,
    resetSendDeal,
    closeModal,
    t,
    appStore
  } = props;
  const { isMotonovo, canSelfServeQuotes } = appStore.uiState;

  const onCloseModal = useCallback(() => {
    closeModal();
    resetSendDeal();
  }, [closeModal, resetSendDeal]);

  let tryAgainHandler;
  if (isExistingCustomer) {
    tryAgainHandler = sendDealToExistingCustomerFromQuickQuote;
  } else if (isQuickQuote) {
    tryAgainHandler = sendDealFromQuickQuote;
  } else {
    tryAgainHandler = sendDeal;
  }

  const formattedCustomerType = customerType ? customerType.toLowerCase() : '';
  const modalTitle = isMotonovo ? null : t('SendDealModalContainer.add_or_find_customer');
  const hideClose = isMotonovo ? currentView === 'LOADING_VIEW' : false;

  return (
    <Modal title={modalTitle} hideClose={hideClose} onClose={closeModal} isOpen={isOpen}>
      {isMotonovo || canSelfServeQuotes ? (
        <SendDealModalContents
          quotes={quotes}
          onCancel={closeModal}
          sendDeal={sendDeal}
          sendDealFromQuickQuote={sendDealFromQuickQuote}
          sendDealToExistingCustomerFromQuickQuote={sendDealToExistingCustomerFromQuickQuote}
          currentView={currentView}
          navigateToCustomerRecord={() => navigateToCustomerRecord(dealershipId, returnedCustomerId, returnedVehicleId)}
          isQuickQuote={isQuickQuote}
          onQuickQuoteProceed={onQuickQuoteProceed}
          existingCustomer={existingCustomer}
          isExistingCustomer={isExistingCustomer}
          onTryAgain={tryAgainHandler}
          customer={customer}
          customerType={formattedCustomerType}
          dealershipId={dealershipId}
        />
      ) : (
        canUseSendDealViaQuoteResultsPage() && (
          <SendDealForm
            customerType={formattedCustomerType}
            journeySource={isQuickQuote ? 'QuickQuote' : 'CustomerRecord'}
            onSendDealToNewCustomer={sendDealFromQuickQuote}
            onSendDealToExistingCustomer={sendDealToExistingCustomerFromQuickQuote}
            customer={customer}
            quotes={quotes}
            isExistingCustomer={isExistingCustomer}
            onCancel={onCloseModal}
          />
        )
      )}
    </Modal>
  );
};

SendDealModalContainer.propTypes = {
  quotes: PropTypes.array,
  modals: PropTypes.object,
  closeModal: PropTypes.func,
  sendDeal: PropTypes.func,
  currentView: PropTypes.string,
  navigateToCustomerRecord: PropTypes.func,
  resetSendDeal: PropTypes.func,
  isQuickQuote: PropTypes.bool,
  onQuickQuoteProceed: PropTypes.func,
  sendDealFromQuickQuote: PropTypes.func,
  sendDealToExistingCustomerFromQuickQuote: PropTypes.func,
  isExistingCustomer: PropTypes.bool,
  existingCustomer: PropTypes.object,
  customer: PropTypes.object,
  dealershipId: PropTypes.string,
  returnedCustomerId: PropTypes.string,
  returnedVehicleId: PropTypes.string,
  isOpen: PropTypes.bool,
  customerType: PropTypes.string
};

const mapStateToProps = (state) => {
  const customerQuoteCustomerId = _.get(state.modals, 'sendDeal.consumerId');
  return {
    modals: state.modals,
    isQuickQuote: _.get(state.modals, 'sendDeal.isQuickQuote'),
    currentView: state.sendDeal.currentView,
    dealershipId: state.dealership.Id,
    customer: getCustomer(state, customerQuoteCustomerId),
    vehicleId: _.get(state.modals, 'sendDeal.vehicleId'),
    quotes: _.get(state.modals, 'sendDeal.quotes'),
    returnedCustomerId: state.sendDeal.returnedCustomerId,
    returnedVehicleId: state.sendDeal.returnedVehicleId,
    isOpen: state.modals.sendDeal ? state.modals.sendDeal.isOpen : false,
    customerType: _.get(state.modals, 'sendDeal.customerType')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(modalActions.close('sendDeal'));
    },
    sendDeal: () => {
      window.ga && window.ga('send', 'event', 'Quote_SendDeal', 'ButtonPress', 'DealSent_ExistingCustomer');
      dispatch(sendDealActions.sendDealToCustomer());
    },
    sendDealFromQuickQuote: (formData) => {
      window.ga && window.ga('send', 'event', 'QuickQuote_SendDeal', 'ButtonPress', 'DealSent_NewCustomer');
      dispatch(sendDealActions.sendDealFromQuickQuote(formData));
    },
    sendDealToExistingCustomerFromQuickQuote: (customerId) => {
      window.ga && window.ga('send', 'event', 'QuickQuote_SendDeal', 'ButtonPress', 'DealSent_ExistingCustomer');
      dispatch(sendDealActions.sendDealToExistingCustomerFromQuickQuote(customerId));
    },
    navigateToCustomerRecord: (dealershipId, consumerId, vehicleId) => {
      dispatch(modalActions.close('sendDeal'));
      dispatch(sendDealActions.resetSendDeal());
      push({
        pathname: `/d/${dealershipId}/consumers/${consumerId}`,
        search: `?${objectToSearchParams({
          vehicleId,
          selectedFinanceTab: 'SentDeals'
        })}`
      });
    },
    resetSendDeal: () => {
      dispatch(sendDealActions.resetSendDeal());
    },
    onQuickQuoteProceed: () => {
      window.ga && window.ga('send', 'pageview', 'send_deal CUSTOMER_DETAILS_VIEW');
      dispatch(sendDealActions.setPage('CUSTOMER_DETAILS_VIEW'));
    }
  };
};

export default compose(
  withTranslation('SendDeal'),
  inject('appStore'),
  observer,
  connect(mapStateToProps, mapDispatchToProps)
)(SendDealModalContainer);
