import { ApplicationSummary } from '~/types/application';
import { ApplicationSummaryVariant, ApplicationSummaryVariantType } from './applicationSummaryTypes';

export type GetApplicationTypeResponse = {
  isPreApproved: boolean;
  isPreApprovalPreSubmitSummary: boolean;
  isPreApprovalPostSubmitSummary: boolean;
  isPreApprovalPostSubmitNotApprovedSummary: boolean;
  isReQuotePreSubmitApplicationSummary: boolean;
  isPreSubmitApplicationSummary: boolean;
  isEditApplicationSummary: boolean;
  isPostSubmitApplicationSummary: boolean;
};
export const getApplicationType = (
  application: ApplicationSummary,
  variant: ApplicationSummaryVariantType
): GetApplicationTypeResponse => {
  const { Quote: quote } = application;
  const { SupportsPreApproval } = quote;

  /*
   * APPLICATION FLAGS
   * -------------------------------
   * canPreApproval
   *
   * The application can be pre-approved
   * Using the PreApprovalData flag as an additional check, because when the application has been pre approved - there's a new APR
   * but it hasn't gone through the application process yet (consumer duty and submitted an actual application with the new APR)
   *
   * isPreApproved
   *
   * The application has already been pre-approved
   * Using every available flag to make sure it's accurate
   * The Status match to "Intercepted" is needed, because all the flags before that can be valid on a submitted application,
   * but only an application with an "Intercepted" status is technically part of the "pre approval" process and not part of the
   * standard application process
   *
   * hasNotBeenSubmitted
   *
   * The application has not been submitted
   * We can only verify that's the case if the application doesn't have a status already
   *
   * hasBeenSubmitted
   *
   * The application has been submitted
   * We can only assume that based on the application have some sort of status - i.e. it has been processed in some way already
   */

  const canPreApprove = SupportsPreApproval === true && application?.PreApprovalData === null;
  const isPreApproved =
    SupportsPreApproval === true &&
    application?.PreApprovalData?.NewAPR !== null &&
    application?.PreApprovalData?.Success === true &&
    application.Status === 'Intercepted';

  const isNotPreApproved =
    SupportsPreApproval === true &&
    application?.PreApprovalData?.NewAPR === null &&
    application?.PreApprovalData?.Success === false;

  const hasNotBeenSubmitted = application.Status === '';
  const hasBeenSubmitted = application.Status !== '';
  const isSubmitting = application.Status === 'Submitting';
  const hasBeenRejectedOnSubmission = application.Status === 'Rejected';

  /*
   * PRE-APPROVAL APPLICATION PAGE TYPES
   * ------------------------------------------
   *
   * isPreApprovalPreSubmitSummary
   *
   * The application has NOT been submitted for pre-approval, but it will support it, which means it will be intercepted if submitted
   * So the main differences with a normal pre-submit application is that it has indicator saying that it will be intercepted rather than sent to the lender
   * We've added an additional variant that ensure this only happens on the Create Application summary page, otherwise it can be valid on more than one page
   *
   * isPreApprovalPostSubmitSummary
   *
   * The application has been submitted (and intercepted), and it has been through the pre-approval process first round, but it's not yet a normal application
   * The status can be Submitting / Intercepted - any other status means it is going to be treated as a normal application (is this a valid assumption?)
   * The additional variant makes sure that we only treat this as a post submit (viewing it) rather than when editing or creating
   *
   * isPreApprovalPostSubmitNotApprovedSummary
   *
   * The application has been submitted and has not been intercepted yet, so it has not been through the pre-approval process
   * The status can be Submitting or Rejected - Not sure if there are other relevant statuses
   * Moreover, the application can be before pre-approval while it's submitted (no pre approval data), or just after when it's rejected
   * The additional variant makes sure that we only treat this as a post submit (viewing it) rather than when editing or creating
   *
   * isReQuotePreSubmitApplicationSummary
   *
   * The application has NOT been submitted (as a normal application), BUT it has been through the pre-approval process first round
   * it will have a status of Intercepted (so different to isPreSubmitApplicationSummary in that way), and it will have a NewAPR assigned
   * The additional variant makes sure that we only treat this as a pre submit - creating an application but from the pre-approval follow up
   */

  /*
   * isPreApprovalPreSubmitSummary and isPreSubmitApplicationSummary can be true at the same time
   * because isPreSubmitApplicationSummary does not consider the more specific checks of canPreApprove
   * But isPreApprovalPreSubmitSummary is more specific and as such can be used to exclude isPreSubmitApplicationSummary
   */
  const isPreApprovalPreSubmitSummary =
    canPreApprove && hasNotBeenSubmitted && variant === ApplicationSummaryVariant.CreateApplication;

  const isPreApprovalPostSubmitNotApprovedSummary =
    (canPreApprove || isNotPreApproved) &&
    (isSubmitting || hasBeenRejectedOnSubmission) &&
    variant === ApplicationSummaryVariant.ViewApplication;

  /*
   * isPreApprovalPostSubmitSummary and isPostSubmitApplicationSummary can be true at the same time
   * because isPreApproved is more specific but can be true at the same time as hasBeenSubmitted
   * But isPreApprovalPostSubmitSummary is more specific and as such can be used to exclude isPostSubmitApplicationSummary
   */
  const isPreApprovalPostSubmitSummary = isPreApproved && variant === ApplicationSummaryVariant.ViewApplication;

  const isReQuotePreSubmitApplicationSummary = isPreApproved && variant === ApplicationSummaryVariant.CreateApplication;

  /*
   * NORMAL APPLICATION PAGE TYPES
   * ------------------------------------------
   *
   * isPreSubmitApplicationSummary
   * The application has NOT been submitted, i.e. it does not have a status yet
   *
   * isEditApplicationSummary
   * The application has been submitted but it is in a status that allows it to be edited (e.g. Rejected)
   *
   * isPostSubmitApplicationSummary
   * The application has been submitted, and cannot be edited, so this is when we just view the summary
   */

  const isPreSubmitApplicationSummary = hasNotBeenSubmitted && variant === ApplicationSummaryVariant.CreateApplication;

  const isEditApplicationSummary = !isPreApproved && variant === ApplicationSummaryVariant.EditApplication;

  const isPostSubmitApplicationSummary = hasBeenSubmitted && variant === ApplicationSummaryVariant.ViewApplication;

  return {
    isPreApproved,

    // Pre-Approval process
    isPreApprovalPreSubmitSummary,
    isPreApprovalPostSubmitSummary,
    isPreApprovalPostSubmitNotApprovedSummary,
    isReQuotePreSubmitApplicationSummary,

    // Normal applications
    isPreSubmitApplicationSummary,
    isEditApplicationSummary,
    isPostSubmitApplicationSummary
  };
};
