import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import QuoteModule from '../../Quoting/containers/QuoteModuleContainer';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import { submitMultipleApplications } from '../../../api/application';

import { observer, inject } from 'mobx-react';
import { compose } from 'redux';
import { withNavigate, withOutletContextProps, withParams } from 'hocs/router';

class CustomerQuote extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false
    };
  }

  UNSAFE_componentWillMount() {
    const vehicle = _.find(this.props.consumer.VehiclesOfInterest, { VehicleId: this.props.params.vehicleId });
    const settings =
      _.chain(this.props.session.Dealerships)
        .find({ Id: this.props.params.dealershipId })
        .get('Settings')
        .value() || {};
    const defaultVaps = this.props.appStore.vapStore.getVisibleDefaultProductsClassFiltered(vehicle?.Class);

    let defaultValues = {
      CashDeposit: settings.DepositValue,
      Term: settings.PreferredTerm || 48,
      AnnualDistance: settings.DefaultAnnualMileage || 10000,
      ValueAddedProducts: defaultVaps || [],
      CustomerType: this.props.consumer.CustomerType,
      OutstandingSettlement:
        this.props.consumer.Settlement &&
        !this.props.consumer.Settlement.Expired &&
        this.props.consumer.Settlement.Value,
      SettlementSource:
        this.props.consumer.Settlement &&
        !this.props.consumer.Settlement.Expired &&
        this.props.consumer.Settlement.Source
    };

    this.setState({
      vehicle,
      defaultValues
    });
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleProceed = (quote) => {
    // Combined quote
    if (quote.VehicleLoan) {
      this.setState({ isLoading: true });
      let quoteIds = [];

      if (quote.PersonalLoan) {
        quoteIds.push(quote.PersonalLoan.QuoteId);
      }
      if (quote.NegativeEquityLoan) {
        quoteIds.push(quote.NegativeEquityLoan.QuoteId);
      }

      quoteIds.push(quote.VehicleLoan.QuoteId);

      submitMultipleApplications(
        this.props.params.consumerId,
        this.state.vehicle.VehicleId,
        quoteIds,
        this.props.consumer.CustomerType,
        null,
        quote,
        this.props.params.dealershipId
      ).then((applicationId) => {
        this.setState({ isLoading: false });
        this.props.navigate(
          `/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/application/${
            applicationId[applicationId.length - 1]
          }`
        );
      });

      // Single quote
    } else {
      this.props.createApplication(this.state.vehicle, quote, this.props.consumer.CustomerType);
    }
  };

  saveQuote = (quote) => {
    this.props.saveCustomerQuote(quote);
  };

  render() {
    const { dealershipId, consumerId } = this.props.params;
    const { canUseCfcIvendiv2 } = this.props.appStore.uiState;
    const canUseSendDeal = this.props.appStore.uiState.canUseSendDeal;

    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: 'Home',
              path: `/d/${dealershipId}`
            },
            {
              name: this.props.t('CustomerQuote.customer_list'),
              path: `/d/${dealershipId}/consumers`
            },
            {
              name: 'Consumer',
              path: `/d/${dealershipId}/consumers/${consumerId}`
            },
            {
              name: this.props.t('CustomerQuote.add_quote')
            }
          ]}
          consumer={this.props.consumer}
        />

        <QuoteModule
          vehicle={this.state.vehicle}
          initialCosts={this.state.defaultValues}
          onCancel={this.handleCancel}
          onProceed={this.handleProceed}
          customerEmail={this.props.consumer.Email}
          enableShowroomCfc={canUseCfcIvendiv2 && this.props.consumer.CustomerType.toLowerCase() === 'consumer'}
          enableSendDeal={canUseSendDeal}
          assignees={this.props.assignees}
          onSave={this.saveQuote}
          isCustomerQuote={true}
          onSendDeal={this.props.onSendDeal}
          customerType={this.props.consumer.CustomerType}
          customerId={consumerId}
          applicationCreationLoading={this.state.isLoading}
        />
      </Page>
    );
  }
}

CustomerQuote.propTypes = {
  params: PropTypes.object.isRequired,
  appStore: PropTypes.object,
  createApplication: PropTypes.func,
  consumer: PropTypes.object,
  session: PropTypes.object,
  application: PropTypes.object,
  location: PropTypes.object,
  onCancel: PropTypes.func,
  assignees: PropTypes.array,
  openSaveQuoteModal: PropTypes.func,
  saveCustomerQuote: PropTypes.func,
  onSendDeal: PropTypes.func
};

export default compose(
  withParams,
  withNavigate,
  withOutletContextProps,
  withTranslation('CustomerQuote'),
  inject(['appStore']),
  observer
)(CustomerQuote);
