import { createQueryKeys } from '@lukemorales/query-key-factory';
// import ms from 'ms';
import { lookupByEmail, lookupByCustomerDetails } from '.';
import { useQuery } from '@tanstack/react-query';
import { LookupByCustomerDetailsParams } from '.';
export const SERVICE_PREFIX = 'lookup';

export const lookupQueries = createQueryKeys(SERVICE_PREFIX, {
  byEmail: ({ email, dealershipId, sector }: Parameters<typeof lookupByEmail>['0']) => ({
    queryKey: [SERVICE_PREFIX, { email, dealershipId, sector }],
    queryFn: () => lookupByEmail({ email, dealershipId, sector })
    // These are currently ignored by the factory https://github.com/lukemorales/query-key-factory/issues/85
    // keepPreviousData: true,
    // cacheTime: ms('5m'),
    // staleTime: ms('5m')
  }),
  byCustomerDetails: (params: LookupByCustomerDetailsParams) => ({
    queryKey: [SERVICE_PREFIX, params],
    queryFn: () => lookupByCustomerDetails(params)
  })
});

export const useLookupByCustomerDetails = (params: LookupByCustomerDetailsParams) => {
  return useQuery(lookupQueries.byCustomerDetails(params).queryKey, lookupQueries.byCustomerDetails(params).queryFn, {
    enabled: params.debouncedValue.length >= 2
  });
};
