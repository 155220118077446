import { FetchedCustomerType } from '~/api/lookups';
import { SendDealFormValues } from './SendDealForm';
import { CustomerTypeEnum, CustomerType } from '~/api/contentService/utils';
import { UseFormReturn } from 'react-hook-form';

export const onCustomerFound = ({
  customer,
  methods,
  updateState,
  defaultValues
}: {
  customer: FetchedCustomerType | null;
  methods: UseFormReturn<SendDealFormValues>;
  updateState: (isCustomerFound: boolean) => void;
  defaultValues: SendDealFormValues;
}) => {
  if (customer) {
    const { Id, CustomerType, CustomerName, Consumer, Corporate, AssignedTo } = customer;
    methods.setValue('Id', Id);
    methods.setValue('CustomerName', CustomerName || '');
    methods.setValue('CustomerType', CustomerTypeEnum[CustomerType] as keyof typeof CustomerTypeEnum);
    methods.setValue('AssignedTo', AssignedTo || []);

    if (CustomerType === CustomerTypeEnum.consumer) {
      methods.setValue('FirstName', Consumer?.Firstname || '');
      methods.setValue('MiddleNames', Consumer?.Middlenames || '');
      methods.setValue('Surname', Consumer?.Surname || '');
      methods.setValue('Email', Consumer?.Email || '');
      methods.setValue('Landline', Consumer?.Landline || '');
      methods.setValue('Mobile', Consumer?.Mobile || '');
    } else {
      methods.setValue('TradingName', Corporate?.TradingName || '');
      methods.setValue('Landline', Corporate?.Landline || '');
      methods.setValue('Mobile', Corporate?.Mobile || '');
    }

    updateState(true);
  } else {
    methods.reset(defaultValues);
    updateState(false);
  }
};
