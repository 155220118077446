import { FacilityType } from '~/api/contentService/utils';
import { ValueAddedProductType } from '~/components/QuoteCard/types';
import AlgoliaVehicle from '~/mobx-models/AlgoliaVehicle';

export const VehicleClassJs = Object.freeze({
  car: 'car',
  lcv: 'lcv',
  bike: 'bike',
  motorhome: 'motorhome',
  touringCaravan: 'touringcaravan',
  van: 'van',
  motorbike: 'motorbike',
  hgv: 'hgv'
});

export enum VehicleClass {
  car = 'car',
  lcv = 'lcv',
  bike = 'bike',
  motorhome = 'motorhome',
  touringCaravan = 'touringcaravan',
  van = 'van',
  motorbike = 'motorbike',
  hgv = 'hgv'
}

export const VehicleConditionJs = Object.freeze({
  used: 'used',
  new: 'new'
});

export enum VehicleCondition {
  used = 'used',
  new = 'new'
}

export const isMotorhomeOrTouringCaravan = (vehicleClass: string) => {
  const loweredVehicleClass = vehicleClass?.toLowerCase();
  return [VehicleClass.motorhome, VehicleClass.touringCaravan].includes(loweredVehicleClass as VehicleClass);
};

export type Vehicle = {
  CapId: number | null;
  CashPrice?: number;
  CdnVehicleImageUrl: string;
  Class: VehicleClass | null;
  Condition: VehicleCondition;
  Derivative: string;
  DerivativeId: number | null;
  Make: string;
  MakeId: number | null;
  Mileage: number | null;
  Model: string;
  ModelId: number | null;
  Price: string;
  RawVehicle: AlgoliaVehicle;
  RegistrationDate: string;
  VehicleId: string;
  VatIncluded: boolean | null;
  Vin: string;
  Vrm: string;
};

export type ApplicationVehicleStatus = {
  AcceptanceFeeLast: number;
  AccessoriesInsurance: number;
  AccessoriesOther: number;
  AccessoriesWarranty: number;
  Advance: number;
  AnnualDistanceQuoted: number;
  BasicPrice: number;
  CashPrice: number;
  Cashback?: number;
  ChargePerOverDistanceUnit: number;
  Commission: number;
  DateOfHandover: string;
  DateOfPurchase: string;
  Deposit: number;
  FacilitatorName: string;
  FinalPayment: number;
  FinanceType: string;
  FirstPayment: number;
  FlatRate: number;
  FollowedBy: number;
  FollowingPayments: number;
  NonVatableItems: number;
  OptionToPurchaseFee: number;
  PartExchange: number;
  RepresentativeApr: number;
  RequestedDeposit: number;
  Settlement: number;
  StandInValue: number;
  Status: string;
  Term: number;
  TotalAmountPayable: number;
  VAT: number;
  VATAmount: number;
};

export type CustomerVehicleOfInterest = {
  CreatedTimestamp: number;
  SanitizedVrm: string;
  VehicleId: string;
  CapId: number | null;
  GlassId: string | null;
  MakeId: number | null;
  ModelId: number | null;
  CapMakeId: number | null;
  CapModelId: number | null;
  Make: string | null;
  Model: string | null;
  Derivative: string | null;
  CashPrice?: number | null;
  Class: VehicleClass;
  Condition: VehicleCondition;
  Vrm: string | null;
  RegistrationDate: string | null;
  Mileage: number | null;
  DealerId: string | null;
  IvendiId: string | null;
  CdnVehicleImageUrl: string | null;
  HiddenOnDashboard: boolean;
  Closed: boolean;
  Status: null | VehicleOfInterestCloseDealStatus;
  Vin: string | null;
  SuppliedMake: string | null;
  SuppliedModel: string | null;
  SuppliedDerivative: string | null;
  SuppliedFuelType: string | null;
  SuppliedBodyStyle: string | null;
  SuppliedDoors: string | null;
  TaxonomyId: number | null;
  TaxonomyType: string | null;
  ExternalReference: string | null;
  VatIncluded: boolean | null;
  LastUpdated: string | null;
  AccuracyScore: number | null;
  Range: string | null;
  isSold: boolean;
};

export type ApplicationVehicle = Omit<CustomerVehicleOfInterest, 'Status' | 'TaxonomyId' | 'isSold'> & {
  Status: ApplicationVehicleStatus | null;
  TaxonomyId: string | null;
};

// This is used for Close Deal Vehicles
export const VehicleOfInterestStatusesJs = Object.freeze({
  SoldFinance: 'SoldFinance',
  SoldCash: 'SoldCash',
  NotAvailable: 'NotAvailable',
  NotOfInterest: 'NotOfInterest'
});
export enum VehicleOfInterestStatuses {
  SoldFinance = 'SoldFinance',
  SoldCash = 'SoldCash',
  NotAvailable = 'NotAvailable',
  NotOfInterest = 'NotOfInterest'
}
export type VehicleOfInterestCloseDealStatus = {
  Status: VehicleOfInterestStatuses;
  StatusDate?: string;
  Notes: string | null;
  CashPrice: number | null;
  BasicPrice: number | null;
  VAT: number | null;
  VATAmount: unknown | null;
  NonVatableItems: unknown | null;
  AccessoriesInsurance: unknown | null;
  AccessoriesWarranty: unknown | null;
  AccessoriesOther: unknown | null;
  PartExchange: number | null;
  DateOfPurchase: string | null;
  DateOfHandover: string | null;
  Settlement: number | null;
  PlatformMeta: null;
  Commission: number | null;
  Deposit: null;
  CashDeposit: number | null;
  FunderCode: string;
  OtherFunder: string | null;
  FinanceType: FacilityType;
  Advance: number | null;
  AnnualDistanceQuoted: number | null;
  ChargePerOverDistanceUnit: number | null;
  AcceptanceFeeLast: number | null;
  OptionToPurchaseFee: number | null;
  FlatRate: number | null;
  RepresentativeApr: number | null;
  Term: number | null;
  FirstPayment: number | null;
  FollowedBy: number | null;
  FollowingPayments: number | null;
  FinalPayment: number | null;
  TotalAmountPayable: number | null;
  AdditionalCommission: number | null;
  InterestCharges: number | null;
  VehiclePrice: number | null;
  TotalPrice: number | null;
  ValueAddedProducts: VehicleOfInterestVap[] | [];
};
export type VehicleOfInterestVap = {
  VapId: string;
  OnlinePurchase: boolean;
  OnlineDescription: string;
  Price: number;
} & Omit<ValueAddedProductType, 'Id'>;
