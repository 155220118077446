import { useTranslation } from 'react-i18next';
import './modalGross.scss';
import Modal from '~/components/Common/Modal/Modal';

const ModalOtherHouseholdIncome = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { t } = useTranslation('Application');
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('ModalOtherHouseholdIncome.other_household_income')}>
      <ModalOtherHouseholdIncomeContent />
    </Modal>
  );
};

const ModalOtherHouseholdIncomeContent = () => {
  const { t } = useTranslation('Application');
  return (
    <div>
      <p>{t('ModalOtherHouseholdIncome.additional_income_from_someone')}</p>
      <section>
        <h1 className="modalGross__listHeader">{t('ModalOtherHouseholdIncome.should_include')}</h1>
        <ul>
          <li>{t('ModalOtherHouseholdIncome.salary_or_pension')}</li>
          <li>{t('ModalOtherHouseholdIncome.additional_income')}</li>
          <li>{t('ModalOtherHouseholdIncome.pension_income')}</li>
          <li>{t('ModalOtherHouseholdIncome.court_approved_maintenance')}</li>
        </ul>
        <h1 className="modalGross__listHeader">{t('ModalOtherHouseholdIncome.do_not_include')}</h1>
        <ul>
          <li>{t('ModalOtherHouseholdIncome.benefits_income')}</li>
          <li>{t('ModalOtherHouseholdIncome.rental_income')}</li>
          <li>{t('ModalOtherHouseholdIncome.overseas_income')}</li>
        </ul>
      </section>
    </div>
  );
};

export default ModalOtherHouseholdIncome;
