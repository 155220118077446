import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import BoldHeader from '../../Common/BoldHeader';
import RadioButton from '../../Common/Form/RadioButton';
import CheckboxWithLabel from '../../Common/Form/CheckboxWithLabel';
import { observer, inject } from 'mobx-react';
import ApplicationPage from './ApplicationPage';
import FormFooter from '../../Common/Form/FormFooter';
import ApplicationSummaryTables from './ApplicationSummaryTables';
import BenefitsAndRisks from './BenefitsAndRisks';
import BenefitsAndRisksFooter from './BenefitsAndRisksFooter';
import MarketingPreferences from '../../Common/MarketingPreferences';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import ToggleSwitch from '../../Common/ToggleSwitch';
import { ArrangedFlags } from '../../../constants';
import { isOnlineSource } from '../../../utils/application';
import { withRouter } from 'hocs/router';
import { compose } from 'redux';
import { canSeeConsumerDutyContent, canSeeInterceptor } from '../../../features';
import { printPageByRef } from '~/core/print';
import { ApplicationSummaryVariantJs } from './applicationSummaryTypes';
import './applicationSummary.scss';
import { trackApplicationSubmitSelected } from '../../../tracking/avoTracking';

/*
 * Served when user reaches the application summary page when on an initial application journey
 * i.e having proceeded with a quote via:
 * - Customer Record: Build A Quote button or Apply button on CFC Summary or View Application button on Finance Application
 * - Dashboard: Quick Quote button
 * - Stock: Quote button on vehicle card
 * - Orders & Deals: View Application And Submit button on Deal Activity page for deal that has been Applied for
 * - Strangely, also when user has clicked 'Review application and resolve issues' CTA on ApplicationStatus
 * --- when user clicks 'Review application and resolve issues' CTA they're served EditApplicationSummary instead
 * --- which does not display application sidebar
 */
class ApplicationSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBenefitsPageRadio: isOnlineSource(this.props.application) ? ArrangedFlags.Online : null,
      hasConfirmedValidStatement: false,
      hasConfirmedReadBenefits: false,
      distanceSaleCheckbox: this.props.application?.Arranged?.DistanceSale ?? (this.canUseDistanceSale ? false : null),
      marketingPreference: this.props.application?.PrivacyPreferences?.LenderMarketingByEmailConsent ?? false,
      isLoading: true
    };
  }

  printAreaRef = React.createRef();

  get canUseDistanceSale() {
    return this.props.appStore.uiState.canUseDistanceSale(
      this.props.application.CustomerType,
      this.props.application.Quote.FunderCode
    );
  }

  UNSAFE_componentWillMount() {
    if (
      this.props.application.Id === this.props.params.applicantId &&
      (this.props.application.Status === 'Not Submitted' || this.props.application.Status === '') &&
      this.props.application.Tags &&
      this.props.application.Tags.multiQuoteRefId &&
      this.props.query.prefill === 'true'
    ) {
      this.handlePreFill();
    } else if (this.props.application.Id !== this.props.params.applicantId) {
      this.props.changeApplication(this.props.params.applicantId).then(() => {
        if (
          (this.props.application.Status === 'Not Submitted' || this.props.application.Status === '') &&
          this.props.application.Tags &&
          this.props.application.Tags.multiQuoteRefId &&
          this.props.query.prefill === 'true'
        ) {
          this.handlePreFill();
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  handlePreFill = () => {
    this.props.appStore.customerStore.fetchCustomerData(this.props.consumer.Id).then(() => {
      let shouldPreFillfromId = this.props.appStore.customerStore.customer.FinanceApplications.reduce((acc, curr) => {
        return (
          acc ||
          (curr.Tags &&
            curr.Tags.multiQuoteRefId === this.props.application.Tags.multiQuoteRefId &&
            curr.LenderStatus !== 'Not Submitted' &&
            curr.LenderStatus !== 'Error' &&
            curr.ApplicantId)
        );
      }, null);

      if (shouldPreFillfromId) {
        this.props.prefillApplication(this.props.application.Id, shouldPreFillfromId).then(() => {
          this.setState({
            isLoading: false
          });
          this.props.setPrefilled();
        });
      } else {
        this.setState({
          isLoading: false
        });
      }
    });
  };
  handleCustomerLocationRadioUpdate = (e) => {
    this.setState({
      selectedBenefitsPageRadio: e.target.value
    });
  };

  handleCheckboxChange = (id, checked) => {
    if (id === 'valid-statements') {
      this.setState({ hasConfirmedValidStatement: checked });
    } else if (id === 'readBenefits') {
      this.setState({ hasConfirmedReadBenefits: checked });
    }
  };

  handleCheckboxDistanceSale = (toggle) => {
    this.setState({
      distanceSaleCheckbox: toggle,
      // If the distance sale checkbox is toggled, only reset "The customer is present" radio if it's currently selected
      selectedBenefitsPageRadio:
        this.state.selectedBenefitsPageRadio === ArrangedFlags.Showroom ? null : this.state.selectedBenefitsPageRadio
    });
  };

  /*
   * Confirmation checkboxes do not need to be displayed/checked if the user is on the pre-approval journey
   * as they will be served to the user on the follow-up requote journey (post-preapproval).
   * Confirmation checkboxes should be displayed/checked if the user is on a standard, non-preapproval journey
   * or if the dealership has auto-submit switched on.
   */
  shouldDisplayConfirmationCheckboxes = () => {
    const {
      Quote: { SupportsPreApproval },
      PreApprovalData
    } = this.props.application;
    const isRequoteJourney = SupportsPreApproval && !!PreApprovalData;

    return canSeeInterceptor() && (isRequoteJourney || !SupportsPreApproval);
  };

  isFullSubmitJourney = () => {
    const {
      Quote: { SupportsPreApproval },
      PreApprovalData
    } = this.props.application;
    const isRequoteJourney = SupportsPreApproval && !!PreApprovalData;
    return canSeeInterceptor() && (isRequoteJourney || !SupportsPreApproval);
  };

  isReadyToSubmit = () => {
    const {
      application: { validationErrors = {} }
    } = this.props;
    const { selectedBenefitsPageRadio, hasConfirmedValidStatement, hasConfirmedReadBenefits } = this.state;
    const shouldDisplayConfirmationCheckboxes = this.shouldDisplayConfirmationCheckboxes();

    const noValidationErrors = Object.keys(validationErrors ?? {}).length === 0;
    const hasSelectedBenefitsPageRadio = !!selectedBenefitsPageRadio;
    const hasConfirmedCheckboxes = hasConfirmedValidStatement && hasConfirmedReadBenefits;

    return (
      noValidationErrors &&
      hasSelectedBenefitsPageRadio &&
      (!shouldDisplayConfirmationCheckboxes || hasConfirmedCheckboxes)
    );
  };

  handleProceed = () => {
    if (this.isReadyToSubmit()) {
      this.handleSubmit();
      this.isFullSubmitJourney() &&
        trackApplicationSubmitSelected({
          financeTerm: this.props.application.Quote.Term,
          financeApplicationId: this.props.application.Id,
          financeCashDeposit: this.props.application.Quote.ActualCashDeposit,
          financeRegularPayment: this.props.application.Quote.FollowingPayments,
          dealershipId: this.props.application.DealershipId,
          quoteId: this.props.application.QuoteId,
          financeFunderCode: this.props.application.Quote.FunderCode,
          financeFunderProductId: this.props.application.Quote.FunderProductUID,
          financeProductType: this.props.application.Quote.FinanceType
        });
    } else {
      if (this.state.selectedBenefitsPageRadio) {
        // A radio option is selected, but checkboxes are not confirmed
        if (this.refs.checkboxWarning) {
          this.refs.checkboxWarning.style.display = 'block';
        }
      } else {
        // No radio option is selected
        if (this.refs.radioWarning) {
          this.refs.radioWarning.style.display = 'block';
        }
      }
    }
  };

  handleSubmit = () => {
    const { params, application, wasPrefilled } = this.props;
    const { legalDocumentStore } = this.props.appStore;
    const { dealershipId, consumerId, applicantId } = params;
    const {
      Quote: { FunderCode, QuoteId },
      Id,
      CustomerType
    } = application;
    const { selectedBenefitsPageRadio, marketingPreference, distanceSaleCheckbox } = this.state;

    const marketing = legalDocumentStore.getLenderMarketing(FunderCode);
    const marketingVersion = marketing ? marketing?.metadata?.Version : 0;

    const submitUrl = `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`;

    const submitData = [
      Id,
      submitUrl,
      CustomerType,
      selectedBenefitsPageRadio,
      marketingPreference,
      marketingVersion,
      QuoteId,
      distanceSaleCheckbox
    ];

    const fullSubmitData = [
      application,
      submitUrl,
      selectedBenefitsPageRadio,
      marketingPreference,
      marketingVersion,
      distanceSaleCheckbox
    ];

    if (wasPrefilled) {
      this.props.fullSubmitApplication(...fullSubmitData);
    } else {
      this.props.submitApplication(...submitData);
    }
  };

  handlePrint = () => {
    printPageByRef(this.printAreaRef);
  };

  toggleMarketing = () => {
    this.setState({
      marketingPreference: !this.state.marketingPreference
    });
  };
  openModal = (name) => {
    return () => {
      this.setState({
        modalOpen: name
      });
    };
  };
  closeModal = () => {
    this.setState({
      modalOpen: ''
    });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="applicationSummary__loading">
          <LoadingSpinner />
        </div>
      );
    }
    const { FunderCode } = this.props.application?.Quote ?? ''; // prevent application from crashing
    const { canSubmitApplication } = this.props.appStore.uiState;

    const canSubmitQuote = canSubmitApplication(FunderCode);
    const readyToSubmit = this.isReadyToSubmit();
    const shouldDisplayConfirmationCheckboxes = this.isFullSubmitJourney();

    return (
      <ApplicationPage>
        <div className="printArea" ref={this.printAreaRef}>
          <ApplicationSummaryTables
            options={this.props.options}
            showConsumerHubInfoPanel={isOnlineSource(this.props.application)}
            application={this.props.application}
            applicationFields={this.props.applicationFields}
            showValidationTicks={true}
            variant={ApplicationSummaryVariantJs.CreateApplication}
          />
          {!canSeeConsumerDutyContent() && (
            <>
              <BoldHeader text={this.props.t('ApplicationSummary.benefits_and_risks')} />
              <BenefitsAndRisks quote={this.props.application.Quote} email={this.props.consumer.Email}>
                <MarketingPreferences
                  funderCode={this.props.application.Quote.FunderCode}
                  toggleMarketing={this.toggleMarketing}
                  hasAcceptedMarketing={this.state.marketingPreference}
                />
              </BenefitsAndRisks>
            </>
          )}
          {shouldDisplayConfirmationCheckboxes && <BenefitsAndRisksFooter />}
        </div>
        {canSubmitQuote && (
          <>
            {shouldDisplayConfirmationCheckboxes && (
              <section>
                <CheckboxWithLabel
                  onChange={(checked) => this.handleCheckboxChange('valid-statements', checked)}
                  id="validStatements"
                  value={this.state.hasConfirmedValidStatement}
                  dataThook="validStatements"
                >
                  {this.props.t('ApplicationSummary.i_can_confirm_that_the_above_statements_are_true')}
                </CheckboxWithLabel>
                <CheckboxWithLabel
                  onChange={(checked) => this.handleCheckboxChange('readBenefits', checked)}
                  id="readBenefits"
                  value={this.state.hasConfirmedReadBenefits}
                  dataThook="readBenefits"
                >
                  {this.props.t(
                    'ApplicationSummary.i_can_confirm_that_the_customer_has_read_and_understood_the_benefits_and_risks_of_the_finance_product_selected_and_is_aware_of_the_existence_and_nature_of_any_commission_to_be_paid_by_the_lender'
                  )}
                </CheckboxWithLabel>
                <div>
                  <div className="applicationSummary__checkboxWarning">
                    {this.props.t('ApplicationSummary.you_must_confirm_this_to_proceed')}
                  </div>
                </div>
              </section>
            )}
            {this.canUseDistanceSale && (
              <div className="applicationSummary__distanceSale">
                <MobxFormLabel htmlFor="distanceSale">Is this a distance sale?</MobxFormLabel>
                <div className="applicationSummary__Toggle">
                  <ToggleSwitch
                    id="distanceSale"
                    isActive={this.state.distanceSaleCheckbox}
                    handleUpdate={this.handleCheckboxDistanceSale}
                    dataThook="distanceSale"
                  />
                </div>
              </div>
            )}
            <div className="applicationSummary__radio">
              {!this.state.distanceSaleCheckbox && (
                <RadioButton
                  id="radio-present"
                  name="customer-location"
                  onChange={this.handleCustomerLocationRadioUpdate}
                  ref="radio1"
                  value={ArrangedFlags.Showroom}
                  checked={this.state.selectedBenefitsPageRadio === ArrangedFlags.Showroom}
                >
                  {this.props.t('ApplicationSummary.the_customer_is_present')}
                </RadioButton>
              )}
              <RadioButton
                id="radio-online"
                name="customer-location"
                onChange={this.handleCustomerLocationRadioUpdate}
                ref="radio2"
                value={ArrangedFlags.Online}
                checked={this.state.selectedBenefitsPageRadio === ArrangedFlags.Online}
              >
                {this.props.t('ApplicationSummary.arranged_online')}
              </RadioButton>
              <RadioButton
                id="radio-phone"
                name="customer-location"
                onChange={this.handleCustomerLocationRadioUpdate}
                ref="radio3"
                value={ArrangedFlags.Phone}
                checked={this.state.selectedBenefitsPageRadio === ArrangedFlags.Phone}
              >
                {this.props.t('ApplicationSummary.arranged_over_the_phone')}
              </RadioButton>
            </div>
            <div className="applicationSummary__checkboxWarning" ref="radioWarning">
              {this.props.t('ApplicationSummary.you_must_specify_this_to_proceed')}
            </div>
          </>
        )}

        <FormFooter
          submitLabel={this.props.t('ApplicationSummary.submit')}
          onSubmit={this.handleProceed}
          submitDisabled={!readyToSubmit}
          hideSubmit={!this.props.appStore.uiState.canGenerateQuotes || !canSubmitQuote}
          submittingState={this.props.application.submittingState}
          onPrint={this.handlePrint}
          errorMessage={
            this.props.application.submittingState === 'error' &&
            this.props.t('ApplicationSummary.an_error_occurred_please_try_again_later')
          }
        />
      </ApplicationPage>
    );
  }
}

ApplicationSummary.propTypes = {
  params: PropTypes.object,
  appStore: PropTypes.object,
  application: PropTypes.object,
  applicationFields: PropTypes.object,
  session: PropTypes.object,
  consumer: PropTypes.object,
  submitApplication: PropTypes.func,
  options: PropTypes.object,
  dealership: PropTypes.object,
  assignees: PropTypes.array,
  wasPrefilled: PropTypes.bool,
  setPrefilled: PropTypes.func
};

export default compose(withRouter, withTranslation('Application'), inject(['appStore']), observer)(ApplicationSummary);
