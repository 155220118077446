import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import Button from '../../Common/Button/Button';
import LenderNotesItem from './LenderNotesItem';
import MiniPanel from '../../Common/MiniPanel';
import MiniPanelHeader from '../../Common/MiniPanelHeader';
import MiniPanelToolBar from '../../Common/MiniPanelToolbar';
import { canSeeInterceptor } from '~/features';
import observerForHooks from '~/hocs/observerForHooks';
import './applicationLenderNotes.scss';

import { useParams } from 'react-router-dom';

const LenderNotes = ({ application, updateDecision, hasAssociatedApplications, appStore }) => {
  const params = useParams();
  const { t } = useTranslation('Application');
  const { canCreateApplications } = appStore.uiState;
  const { updatingState, LenderNotes, Quote, Status, CustomerType } = application;
  const title = hasAssociatedApplications ? Quote.ProductName : '';
  const notes = _.orderBy(LenderNotes, 'Timestamp', 'desc');

  const updateNotes = () => {
    updateDecision(params.applicantId, CustomerType);
  };

  const formattedTitle = `${t('LenderNotes.proposal_history')}${title && ` - ${title}`}`;

  return (
    <div className="applicationLenderNotes__lenderNotes" data-th="lenderNotes">
      <MiniPanel>
        <MiniPanelHeader title={formattedTitle} />
        {canCreateApplications && (
          <MiniPanelToolBar>
            <div className="applicationLenderNotes__loadingButton">
              <Button
                isLoading={updatingState === 'loading'}
                buttonStyle="primary"
                onClick={updateNotes}
                stretch={false}
                dataThook="updateDecision"
                disabled={canSeeInterceptor() && Status === 'Intercepted'}
              >
                {t('LenderNotes.update_decision')}
              </Button>
            </div>
          </MiniPanelToolBar>
        )}

        <div className="applicationLenderNotes__items">
          {notes.length === 0 ? (
            <div className="applicationLenderNotes__noNotes">
              {t('LenderNotes.lender_notes_for_this_application_are_unavailable_at_this_time')}
            </div>
          ) : (
            notes.map((note) => <LenderNotesItem note={note} key={note.Timestamp} />)
          )}
        </div>
      </MiniPanel>
    </div>
  );
};

LenderNotes.propTypes = {
  application: PropTypes.object,
  updateDecision: PropTypes.func,
  hasAssociatedApplications: PropTypes.bool,
  appStore: PropTypes.any
};

export default inject('appStore')(observerForHooks(LenderNotes));
