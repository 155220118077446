export enum ApplicationSummaryVariant {
  CreateApplication = 'CreateApplication',
  EditApplication = 'EditApplication',
  ViewApplication = 'ViewApplication'
}
export type ApplicationSummaryVariantType = keyof typeof ApplicationSummaryVariant;
export const ApplicationSummaryVariantJs = Object.freeze({
  CreateApplication: 'CreateApplication',
  EditApplication: 'EditApplication',
  ViewApplication: 'ViewApplication'
});
